import "./App.css";
import Header from "../src/common/Header";
import SideNav from "./common/SideNavigation";
import { Routes, Route, useNavigate, useNavigationType, useLocation } from "react-router-dom";
import { constants } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import { Spinner }from "./common/Spinner";
import { useAuth } from "react-oidc-context";
import { useEffect, useRef, useState } from "react";
import { setDeviceInfo, setDistrictAndStateIds, setDistrictAndStateNames, setEmlRolesFlag, setuserInfo } from "./redux/slice/userInfo";
import { NoAccess } from "./common/Noaccess";
import GlobalMessage from "./common/GlobalMessage";
import { setAlert } from "./redux/slice/GlobalMessage";
import { setWebSocket } from "./redux/slice/notificationWebSocket";
import { getDistrictAndStateWithCid, getStateOrDistNames, getTermsAndConditions } from "./services/muleSoftServices";
import { setnewUser, setSupportMenuBtn, setTAndC, setUserSupport } from "./redux/slice/newUser";
import { get, isEmpty } from "lodash";
import { Button, GlobalHeader } from "@cambridgeassessment/cambridge-ui";
import { CentreSelectionModal } from '../src/common/Modal';
import { setTabNumber } from "./redux/slice/sideNavigation";
import { setDistrictNavs, setRecentSelectedSeries, setSeries } from "./redux/slice/passRate";

function App() {
  const routes = constants.routes.map((r,i) => {
      return <Route key={i} path={r.path} element={r.component} />;
    }),
    firstTimeOrUpdatedTermUser = useSelector((state) => state.new),
    alert = useSelector((state) => state.globalMessage),
    navigate = useNavigate(),
    location = useLocation(),
    navigateType = useNavigationType(),
    dispatch = useDispatch(),
    [netConnection, setNetConnection] = useState(true),
    months = useRef([]),
    initialSeries = useRef({}),
    loading = useRef(false),
    initialized = useRef(false),
    auth = useAuth(),
    userAccess = useRef(true),
    termsVisible = useRef(false),
    openCentreSelectionModal = useRef(false),
    centreFromModal = useRef([]),
    roleLevel = useRef(""),
    websocket = useRef(),
    websocketInitialized = useRef(false);
    let deviceType = navigator.userAgent,
    nonDesktop = constants.deviceRegEx.test(deviceType),
    deviceChange = window.innerWidth<769 || nonDesktop;
    if(!deviceChange){
      nonDesktop = window.matchMedia("only screen and (max-width: 480px)").matches;   
    };
    let nonEmptyOrgs = [];

  useEffect(()=>{
    if(navigateType === "POP" && get(location, 'pathname', "")){
      navigate(1);
    }
  },[location,navigateType]);

  window.ononline = (e) =>{
    setNetConnection(true);
  }

  window.onoffline = (e) =>{
    setNetConnection(false);
  }

  if (performance.getEntriesByType("navigation")[0].type === "reload") {
    loading.current = true;
    window.location.pathname = "/";
  }

  if (
    auth &&
    !auth.isAuthenticated &&
    !auth.isLoading &&
    !auth.error &&
    !auth.activeNavigator
  ) {
    auth.signinRedirect();
  }

  if (auth?.isLoading) {
    return <p>Loading...</p>;
  }

  if (auth?.error) {
    sessionStorage.clear();
    auth.signinRedirect();
  }

  const termsAndConditions = async(token,rolename,uid,stateId) =>{
    const params ={token:token,roleName:rolename,uid:uid,stateId:stateId},
          tAndCResp = await getTermsAndConditions(params);
          termsVisible.current = true;
    if(tAndCResp?.data?.status === 'N'){
      dispatch(setnewUser({newUser:false}));
      navigate('/dashboard');      
    }else{
      dispatch(setTAndC({terms:tAndCResp?.data}));
      dispatch(setnewUser({newUser:true}));
      navigate("/");
    }    
  }

  const getDisStateWithCentre = async(token,centreId,roleName,uid,bpId) =>{
    const params = {token:token,centreId:centreId,
                    roleName:roleName,bpId:bpId},
          disStateResp = await getDistrictAndStateWithCid(params);
    let dId,sId;
    if(disStateResp?.data?.data && disStateResp.data.data?.length>0){
      const {districtID,stateCode} = disStateResp.data.data[0];
      dId = districtID;
      sId = stateCode;
    }else if(auth?.user?.profile?.hierarchy?.length>0){
      const {districtId,stateId} = auth.user.profile?.hierarchy[0];
      dId = districtId;
      sId = stateId;      
    }
    dispatch(setDistrictAndStateIds({district:dId,state:sId}))
    termsAndConditions(token,roleName,auth?.user?.profile?.uid,sId);
  }

  const getDistOrStateNameWithIds = async(params)=>{
    const names = await getStateOrDistNames(params);
    if(names?.data?.data && names.data.data?.length>0){
      const {centreState,districtName,stateCode,districtID} = names.data.data[0];
      dispatch(setDistrictAndStateNames({stateName:!isEmpty(centreState)?centreState:"",
                                         districtName:!isEmpty(districtName)?districtName:""}));
      dispatch(setDistrictAndStateIds({state:!isEmpty(stateCode)?stateCode:"",
                                         district:!isEmpty(districtID)?districtID:""}));
      termsAndConditions(params.token,params.roleName,auth.user.profile.uid,stateCode);
    }
  }

  const setUserData = (org) =>{
    const {profile:{name, roles, orgs, hierarchy,uid}} = auth.user,
          {roleKeys,roleNames} =  constants.sideNav;
    for(let i in roleNames){
      let userEligible,rolesCheck,roleBpid;
      if(roles.length>1){
        if(!isEmpty(org)){
          let specifiedRole = roles.filter(b=>b.bpid === org[0].bpid);
          rolesCheck = specifiedRole;          
        }else{
          rolesCheck = roles;
        }
        for(let n in rolesCheck){
          userEligible = rolesCheck[n].roleNames.filter(r=>r.toLowerCase() === roleNames[i].toLowerCase());
          roleBpid = rolesCheck[n].bpid;
          if(userEligible.length>0){
            break;
          }
        }
      }else{
        userEligible = roles[0]?.roleNames?.filter(r=>r.toLowerCase() === roleNames[i].toLowerCase());
        roleBpid = roles[0]?.bpid;
      }
      userAccess.current = userEligible?.length>0;
      if(userAccess.current){                 
          let orgData;
          if(!isEmpty(org)){
            centreFromModal.current = org;
            orgData = org;
          }else if(isEmpty(org) && centreFromModal.current.length){
            orgData = centreFromModal.current;
          }else{
            orgData = orgs.filter(o=>o.bpid === roleBpid);
          }
          dispatch(setuserInfo({ userInfo: { name: name, roles: userEligible[0], orgs:orgData, totalLoggedInData:auth.user,
          roleLevel:roleKeys[roleNames[i]], hierarchy:hierarchy, uid:uid } }));
          dispatch(setDeviceInfo({userInfo: {nonDesktop:nonDesktop} }));

          if((!initialized.current || openCentreSelectionModal.current)){          
          seriesToDisplay();
          initiateWebsocketConnection();      
          if(roleKeys[roleNames[i]] === "exams" || roleKeys[roleNames[i]] === "head" || roleKeys[roleNames[i]] === "teacher"){
           getDisStateWithCentre(auth.user.access_token,
                                 orgData[0]?.sourceSystems[0]?.orgId,
                                 userEligible[0],
                                 uid,roleBpid);
          }
          else if(roleKeys[roleNames[i]] === "district" || roleKeys[roleNames[i]] === "state"){
           let params = {
              token:auth.user.access_token,
              roleName:userEligible[0],
              stateId:!isEmpty(hierarchy) && hierarchy[0]?.stateId,
              districtId:!isEmpty(hierarchy) && hierarchy[0]?.districtId,
              bpId:roleBpid
            }
            getDistOrStateNameWithIds(params);            
          }
          else if(roleKeys[roleNames[i]] === "support"){
             termsVisible.current = true;
             dispatch(setnewUser({newUser:false}));
             dispatch(setSupportMenuBtn({hideMenu:deviceChange}));
          }
          initialized.current = true; 
        }                       
        roleLevel.current = roleKeys[roleNames[i]];
        break;
      }
    }
  }

  const seriesToDisplay = () =>{
    const date = new Date(),
          month = date.getMonth()+1,
          year = date.getFullYear(),
          seriesList = [];

    if(month <7){
      months.current = [`June ${year}`,`November ${year-1}`,`June ${year-1}`];
    }else if(month>6 && month <12){
      months.current = [`November ${year}`,`June ${year}`,`November ${year-1}`];
    }else{
      months.current = [`June ${year+1}`,`November ${year}`,`June ${year}`];
    }

    months.current.forEach(s => {
      let grpLabel = "",
          yearCount = months.current.filter(f=>f.includes(s));
      if(s.includes(year)){
        grpLabel = year;
      }else if(s.includes(year-1)){
        grpLabel = year-1
      }else if(s.includes(year+1)){
        grpLabel = year+1
      }
      if(seriesList.length>=0){
        let yearExist = seriesList.filter(l=>l.label === grpLabel);
        if(!yearExist.length){
          seriesList.push({tag:"group",label:grpLabel})   
        }
      }
      yearCount.forEach(y=>{
        seriesList.push({tag:"item",label:y})
      })          
    });
    let seriesArr = seriesList.filter(s=>s.label === months.current[0]);
    initialSeries.current = seriesArr[0];
    dispatch(setRecentSelectedSeries({userSelectedSeries:seriesArr[0]}));    
    dispatch(setSeries({seriesDropList:seriesList,seriesLabels:months.current}));
  }

  const initiateWebsocketConnection = () =>{
    if(!websocketInitialized.current){
      const websocketUrl = process.env.REACT_APP_WEB_SOCKET_URL,
            url = `${websocketUrl}?uuid=${auth.user.profile?.uid}&JWToken=${auth.user.access_token}`,
            ws = new WebSocket(url);
      websocket.current = ws;
      ws.onclose = () =>{
        websocketInitialized.current = false;
        console.log('socket auto closed');
        initiateWebsocketConnection();
        console.log('socket reinitiated post auto close');
        websocketInitialized.current = true;
      }
      ws.onerror = () =>{
        websocketInitialized.current = false;
        console.log('error from socket connection');
        initiateWebsocketConnection();
        console.log('socket reinitiated after error');
        websocketInitialized.current = true;
      }
      websocketInitialized.current = true;
      dispatch(setWebSocket({ notificationWebSocket: { websocketInstance: ws, notificationMsg: "data" }}));
      if(!initialized.current){
        sendPingToMakeWebSocketAlive();
      }
    }
  }

  const sendPingToMakeWebSocketAlive = () =>{
    setInterval(() => {
      websocket.current?.send(JSON.stringify({ 'action': 'ping' }));
    }, 300000); 
  }

  if (auth?.isAuthenticated) {
    const {profile:{orgs}} = auth.user;
    if(orgs?.length>1){
      nonEmptyOrgs = orgs.filter(org => !isEmpty(org.sourceSystems[0]?.orgId))
    }else{
      nonEmptyOrgs = orgs;
    }
    if(!initialized.current){
      if(nonEmptyOrgs.length>1){
        openCentreSelectionModal.current = true;
      }else if(!openCentreSelectionModal.current){
        setUserData(null);      
      } 
    }        
  }
    
  if(alert.message){
    window.scrollTo(0,0);
    setTimeout(() => {
      dispatch(setAlert({message:"",status:""}));
    }, 10000);
  }

  const openResponsiveSideNav = () => document.getElementById("side_menu").style.display = "block";
  const closeResponsiveSideNav = () => document.getElementById("side_menu").style.display = "none";

  const closeCentreSelectionModal = (org) =>{
    dispatch(setTabNumber({managementTabNumber:-1,reportTabNumber:0}));
    setUserData(org);   
    openCentreSelectionModal.current = false;
  }

  window.addEventListener('resize',(e)=>{
    let element = document.getElementById("side_menu");
    if(!openCentreSelectionModal.current && !firstTimeOrUpdatedTermUser.new && !isEmpty(element)){      
     if(e.target.innerWidth > 768){
       openResponsiveSideNav();
       dispatch(setDeviceInfo({userInfo: {nonDesktop:false} }));
     }else{
       closeResponsiveSideNav();
       dispatch(setDeviceInfo({userInfo: {nonDesktop:true} }));
     }
    }
  })

  if(loading.current && netConnection){
    return(
      <div className="App">
        <Header></Header>
        <Spinner />       
      </div>
    );
  }else if(openCentreSelectionModal.current && netConnection){
      return (
        <div className="App">        
        <Header></Header>
        <CentreSelectionModal orgs={nonEmptyOrgs} open={openCentreSelectionModal.current} close={closeCentreSelectionModal} />
        </div>
      )
  }else if(userAccess.current && termsVisible.current && netConnection){
    return(
      <div className="App">
        {nonEmptyOrgs.length>1 && <GlobalHeader className="selectCentre"
          leftContainer={
            <p className={`mt-3 ${window.innerWidth < 769 ?"mx-2":"mx-3"}`}>
              <a href="#" onClick={() => {openCentreSelectionModal.current = true}}>
                <span className="s_centre">{constants.switchCentreLogo} {constants.switchCentreText}</span>
              </a>
            </p>
          }
        />}
        <Header enableSticky={nonEmptyOrgs.length>1}></Header>               
          <>        
          {firstTimeOrUpdatedTermUser.support && !loading.current &&
           <div className="support_top">
              <div className="banner_column mt-1 mb-1 text-start">
                <h3 className="emulate_text text-uppercase mb-0 pt-2">you are emulating:</h3>
                <p className="eml_subtxt mb-0">{firstTimeOrUpdatedTermUser.emlKey} : <strong className="eml_subtxt_main">{firstTimeOrUpdatedTermUser.emlValue}</strong></p>
              </div>
              <div className="banner_column mt-2 text-sm-end">
                <Button size="small" 
                        variant="outlined" 
                        className="eml_btn eml_btn_align" 
                        onClick={()=>{
                              navigate('/support-admin');
                              dispatch(setUserSupport({support:false}));
                              dispatch(setEmlRolesFlag({emlFlags:{centre:false,district:false,state:false}}));
                              dispatch(setSupportMenuBtn({hideMenu:deviceChange}));
                              dispatch(setRecentSelectedSeries({userSelectedSeries:initialSeries.current}));
                              dispatch(setDistrictNavs({districtNavs:[]}));
                              }}>Finish emulation</Button> 
              </div>
            </div>}         
            {!firstTimeOrUpdatedTermUser.new && 
              <div id="side_menu_btn" 
                   hidden={firstTimeOrUpdatedTermUser.menuHide} 
                   className={`px-4 pt-3 mb-2 text-start col-1 ${window.innerWidth < 769 && nonEmptyOrgs.length>1 ?'menu_btn_mt':""}`}>
                    <button className="menu_button p-2 px-3" onClick={openResponsiveSideNav}>Menu</button>
              </div>}
            <div className={`display ${firstTimeOrUpdatedTermUser.support?"support":
                                       firstTimeOrUpdatedTermUser.new && nonEmptyOrgs.length>1 && window.innerWidth < 769 ? "pt-5":""}`}>
                {!firstTimeOrUpdatedTermUser.new && (
                  <nav id='side_menu' className="side_nav">
                    <SideNav closeRespSideNav={closeResponsiveSideNav} />
                  </nav>
                )}              
                <main className="content p-4 mb-5">
                  {alert.message && <GlobalMessage/>}
                  <Routes>{routes}</Routes>
                </main>
            </div>            
          </>                  
      </div>
    );
  }else if(!userAccess.current && netConnection){
    return(
      <div className="App">
        <Header></Header>
        <NoAccess />
      </div>
    )
  }else if(!netConnection){
    return(
      <div style={{ position:'absolute', 
        top: '50%',left:'50%',
        marginTop:`${-80/2}px`,
        marginLeft: `${-200/2}px`}}>
        <div>{constants.noNetwork}</div>
        <div className="ml_n35" style={{fontWeight:"bold",fontSize:"x-large"}}>It seems like you're offline!</div>
      </div>
    ) 
  }
}
export default App;