import {createSlice} from '@reduxjs/toolkit';

export const studentReport = createSlice({
    name:'studentReport',
    initialState:{dataRows:[],allReportData:{},selectedBtn:"",cIndex:""},
    reducers:{
        setStudentReport:(state,action)=>{
            return {...state,
                    dataRows:action.payload.rows}
        },
        setTotalReportData:(state,action)=>{
            return {...state,
                    allReportData:action.payload.totalReportData}
        },
        setStudentDetails:(state,action)=>{
            return {...state,
                    selectedBtn:action.payload.selectedBtn,
                    cIndex:action.payload.currentIndex
            }
        }
    }
});

export const {setStudentReport,setTotalReportData,setStudentDetails} = studentReport.actions;
export default studentReport.reducer;