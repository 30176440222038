import { Button, Card, Notification, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@cambridgeassessment/cambridge-ui";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GrpDropDown } from "../../common/Dropdown";
import { Spinner } from "../../common/Spinner";
import { constants } from "../../constants";
import "./GradeDistribution.css";
import { getGradeDistributionList } from "../../services/muleSoftServices";
import { borderBottom } from "@mui/system";
import { useReactToPrint } from "react-to-print";
import labels from "../../common/labels";
import { setRecentSelectedSeries } from "../../redux/slice/passRate";
import { numberSorting } from "../../common/validations";

const GradeDistribution = () =>{
    const savedResp = useSelector((state) => state.pass), 
        dispatch = useDispatch(), 
        initialState = {
            seriesSelectedValue:savedResp.selectedSeries,
            qualificationSelectedValue:{},
            spin:true
    },
    [initialData,setInitialData] = useState(initialState),
    sort = useRef({key: "", ascending: true, id:"", lName:"", colType:"", arrToSort:[]}),
    [levels,setLevels] = useState([]),   
    spinnerRef = useRef(""),
    initialized = useRef(false),
    seriesList = useRef([]),
    gradeRef = useRef(null),
    userInfo = useSelector((state) => state.userInfo),
    sortHeadersObj = constants.gradeDistributionStaticHeaders;

    useEffect(()=>{
        let firstSeries;
        const {seriesSelectedValue} = initialData;
        if(!isEmpty(savedResp.seriesLabels) && !initialized.current && !isEmpty(savedResp.seriesDropList)){
            initialized.current = true;
            seriesList.current = savedResp.seriesDropList;
            firstSeries = savedResp.seriesDropList?.filter(s=>s.label === savedResp.seriesLabels[0]);
            setGradeParams(!isEmpty(seriesSelectedValue)?initialData.seriesSelectedValue?.label:firstSeries[0].label);
            if(isEmpty(seriesSelectedValue)){
                setInitialData((previousState)=>{
                    return {...previousState,seriesSelectedValue:firstSeries[0]}
                })
            }
        }       
    },[])

    const setGradeParams = async(series) =>{
        const {qualification} = constants.dropDownList;
        let totLevels = [];
        for(let i=0;i<qualification.length;i++){          
          for(const [key,valueObj] of Object.entries(sortHeadersObj[qualification[i].label])){
            if(key === 'schoolGte'){
                valueObj.sortVisible = true;
            }else{
                valueObj.sortVisible = false;
            }
            valueObj.sortOrder = 'asc';
          }         
          let obj = await getGradeData(series,qualification[i].label);
          if(!isEmpty(obj)){
            totLevels.push(obj);
          }
        }
        totLevels.forEach(t=>{
            let sortedList = numberSorting(t.totRowsInLevel,{ascending:true,key:'colE',needSort:true});
            t.totRowsInLevel = sortedList;
        })
        setLevels(totLevels);      
        spinnerRef.current?.spinnerOpen();
        setInitialData((previousState)=>{
            return {...previousState,spin:false}
        })
    }
    
    const selectedOption = (key, li) => {
        sort.current = {key: "", ascending: true, id:"", lName:"", colType:"", arrToSort:[]};
        dispatch(setRecentSelectedSeries({userSelectedSeries:li}));
        setGradeParams(li.label);
        setInitialData((previousState)=>{
            return {...previousState,spin:true,[key]: li}
        })
    }

    useEffect(()=>{
        spinnerRef.current?.spinnerOpen();
      },[initialData.spin])

    const getGradeData = async(series,qual) =>{
        const {totalLoggedInData,roleFromSAP,orgs,roleLevel} = userInfo,
              {gradeDistribution} = constants.sideNav.roleBaseApiKeys,
              params = {token:totalLoggedInData.access_token,
                        centreId:orgs[0]?.sourceSystems[0]?.orgId,
                        series:series,
                        quals:qual,
                        bpId:orgs[0]?.bpid,
                        roleName:roleFromSAP,
                        roleLevel:gradeDistribution[roleLevel]},
        gradeResp = await getGradeDistributionList(params);
        if(!isEmpty(gradeResp?.data)){
            let levelData = gradeResp.data[qual];
            if(levelData?.length>=0){
                let obj={},eachLevelTotRows=[];
                levelData.forEach((l,i)=>{
                    let gradeHeaders=[],gradeValues=[];
                    for(let key in l.grades){
                        gradeHeaders.push({col:key, id:key, sortVisible:false, sortOrder:'asc', class:'w-5 text-center', [qual]:levelData});
                        gradeValues.push(l.grades[key]);
                    }
                    eachLevelTotRows.push({syllabus:l.syllabus+" - "+l.syllabusName,
                                            sylId:l.syllabus,
                                            examsCount:l.totalCount,
                                            gradeVals:gradeValues,
                                            colC:l.passrateC,
                                            colE:l.passrate})                        
                    obj={levelName:qual,
                         examHead:'Exams',
                         gradeHeads:gradeHeaders,
                         geC:<>&ge;C</>,
                         geE:<>&ge;E</>}
                });
                obj.totRowsInLevel = eachLevelTotRows;
                return obj; 
            }
        }               
    }

    const downloadPdf = useReactToPrint({
        bodyClass:"m-2 p-2",
        documentTitle:'grade_Distribution',
        content: () => gradeRef.current
      }); 
    
    const dataSort = (column,totSortCols,tableLevel,columnType) =>{
        let arrayToSort = [],levelsCpy = [...levels];
            if(column.id !== sort.current.id || (!isEmpty(sort.current.lName) && tableLevel.levelName !== sort.current.lName)){
                if(!isEmpty(sort.current.id)){
                    if(tableLevel.levelName !== sort.current.lName){
                       sort.current.id = "";                        
                       staticColSort(tableLevel,column.id);
                       dynamicColSort(levelsCpy,tableLevel);                           
                    }else{                         
                        if(columnType === 'dynamic'){                          
                            arrayToSort = column[tableLevel.levelName].map(c =>{
                                return {sylId:c.syllabus,grade:c.grades[column.col]}
                            })
                        }                        
                        let previousCol = totSortCols[sort.current.id];
                        if(isEmpty(previousCol)){
                            dynamicColSort(levelsCpy,tableLevel);                             
                        }else{
                            previousCol.sortVisible = false;
                        }         
                    }                   
                }            
                if(isEmpty(sort.current.id)){                   
                    staticColSort(tableLevel,column.id);
                    dynamicColSort(levelsCpy,tableLevel);
                }
                column.sortOrder = 'asc';                
                column.sortVisible = true;
            }else{
                column.sortOrder = column.sortOrder === 'asc'?'desc':'asc'
                if(columnType === 'dynamic'){
                    if(column.sortOrder === 'asc'){
                        arrayToSort = column[tableLevel.levelName].map(c =>{
                            return {sylId:c.syllabus,grade:c.grades[column.col]}
                        })
                    }                          
                }                
                if(columnType === 'dynamic'){
                    staticColSort(tableLevel);
                }
                
            }
            sort.current = { key: column.col, 
                            ascending: column.sortOrder === 'asc'?true:false, 
                            id:column?.id, 
                            lName:tableLevel.levelName,
                            colType:columnType,
                            arrToSort:arrayToSort }

            const {key,ascending,colType,lName,arrToSort} = sort.current;          
    
            for(let i in levelsCpy){
                if(levelsCpy[i].levelName === lName){
                    if(colType === 'dynamic'){
                        let sortedArr = numberSorting(arrToSort,{ascending:ascending,key:'grade',needSort:true});
                        if(sortedArr.length){
                            levelsCpy[i].totRowsInLevel = sortedArr.map(s=>{
                                let levelObj = levelsCpy[i]?.totRowsInLevel.filter(f=>f.sylId === s.sylId);
                                return levelObj[0];                                             
                            })
                        }else{
                            let rowsCpy = [...levelsCpy[i]?.totRowsInLevel];
                            levelsCpy[i].totRowsInLevel = rowsCpy.reverse();
                        }
                    }else{
                        levelsCpy[i].totRowsInLevel = numberSorting(levels[i]?.totRowsInLevel,{ascending:ascending,key:key,needSort:true});
                    }
                    setLevels(levelsCpy);
                    break;                        
                }
            }
    }

    const staticColSort = (tableLevel,colId) =>{
        for(const [key,valueObj] of Object.entries(sortHeadersObj[tableLevel.levelName])){
            if(valueObj.sortVisible && key !== colId){
                valueObj.sortVisible = false;
                break;
            }
        }
    }

    const dynamicColSort = (levelsCpy,tableLevel) =>{
        let prevSortDataToRevert = levelsCpy.filter(l=>l.levelName === tableLevel.levelName);
        if(prevSortDataToRevert.length){
            let prevSortCol = prevSortDataToRevert[0].gradeHeads.filter(g=>g.sortVisible === true);
            if(prevSortCol.length){
                prevSortCol[0].sortVisible = false;
            }                            
        }
    }

    

    return (        
        <>
            {initialData.spin?<Spinner ref={spinnerRef} />:
            <div ref={gradeRef}>
            <div className="row">
                <div className="col-12 row">
                    <div className="txt">
                        <span className="lbl dash_lbl text-uppercase">grade distribution</span>
                    </div>
                    <div className="sub_text dash_sub_text mt-2">
                        View grade distribution for syllabuses you teach. {labels.TEACHER_SUB_HEADER2}{" "}
                        <a href={labels.TEACHER_SUB_HEAD_LINK} target="_blank" className="link">
                            {labels.TEACHER_SUB_HEADER3}
                        </a>.
                    </div>
                </div>
                <div className={`col-12 row ${levels.length>0?"d_contents":''}`}>
                    <div className="col-lg-3 col-md-12 col-sm-12 text-start">
                        <GrpDropDown class="text-start"
                            onChange={selectedOption}
                            selectedValue={initialData.seriesSelectedValue.label}
                            userSelectedDropdown='seriesSelectedValue'
                            label={constants.passRate.dropDownLabels.series} 
                            list={seriesList.current}
                            option='label' value='label' />
                    </div>
                    <div className={`col-lg-9 col-md-12 col-sm-12 mt-3 ${userInfo.smallScreen?"text-start":"text-end p-0"}`}>
                        {levels.length>0 && <Button variant="outlined" onClick={downloadPdf}>{constants.dashboard.downloadIconBlue} Download (pdf)</Button>}
                    </div>
                </div>                
                {levels.length>0 ? levels.map((l,i)=>{                   
                    return(
                            <TableContainer className="mt-4 table_bg col-12" key={i}>
                                <Table>
                                    <TableHead className="t_border_none">
                                        <TableRow>
                                            <TableCell className={sortHeadersObj[l?.levelName]['sylCode'].class}>
                                                <TableSortLabel id={sortHeadersObj[l?.levelName]['sylCode'].id}
                                                                active={sortHeadersObj[l?.levelName]['sylCode'].sortVisible} 
                                                                direction={sortHeadersObj[l?.levelName]['sylCode'].sortOrder}                                                                 
                                                                onClick={() => dataSort(sortHeadersObj[l?.levelName]['sylCode'],sortHeadersObj[l?.levelName],l,'static')}>
                                                                {l?.levelName}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell className={sortHeadersObj[l?.levelName]['totCount'].class}>
                                                <TableSortLabel id={sortHeadersObj[l?.levelName]['totCount'].id}
                                                                active={sortHeadersObj[l?.levelName]['totCount'].sortVisible} 
                                                                direction={sortHeadersObj[l?.levelName]['totCount'].sortOrder}                                                                 
                                                                onClick={() => dataSort(sortHeadersObj[l?.levelName]['totCount'],sortHeadersObj[l?.levelName],l,'static')}>
                                                                {l?.examHead}
                                                </TableSortLabel>
                                            </TableCell>
                                            {l?.gradeHeads?.length>0 && l?.gradeHeads.map((gradeObj,j)=>{
                                                return(<TableCell key={j} className={gradeObj.class}>
                                                            <TableSortLabel active={gradeObj.sortVisible} 
                                                                            direction={gradeObj.sortOrder} 
                                                                            onClick={() => dataSort(gradeObj,sortHeadersObj[l?.levelName],l,'dynamic')}>
                                                                    {gradeObj.col} 
                                                            </TableSortLabel>
                                                        </TableCell>)
                                            })}
                                            <TableCell className={sortHeadersObj[l?.levelName]['pRateC'].class}>
                                                <TableSortLabel id={sortHeadersObj[l?.levelName]['pRateC'].id}
                                                                active={sortHeadersObj[l?.levelName]['pRateC'].sortVisible} 
                                                                direction={sortHeadersObj[l?.levelName]['pRateC'].sortOrder}                                                                 
                                                                onClick={() => dataSort(sortHeadersObj[l?.levelName]['pRateC'],sortHeadersObj[l?.levelName],l,'static')}>
                                                                {l?.geC}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell className={sortHeadersObj[l?.levelName]['schoolGte'].class} >
                                                <TableSortLabel id={sortHeadersObj[l?.levelName]['schoolGte'].id}
                                                                active={sortHeadersObj[l?.levelName]['schoolGte'].sortVisible} 
                                                                direction={sortHeadersObj[l?.levelName]['schoolGte'].sortOrder}                                                                
                                                                onClick={() => dataSort(sortHeadersObj[l?.levelName]['schoolGte'],sortHeadersObj[l?.levelName],l,'static')}>
                                                    {l?.geE}
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {l?.totRowsInLevel?.length>0 && l?.totRowsInLevel.map((t,k)=>{
                                        return(<TableRow key={k} className="t_border_none">
                                            <TableCell className="w-auto">{t?.syllabus}</TableCell>
                                            <TableCell className="w_grade text-center">{Number(t?.examsCount)}</TableCell>
                                            {t?.gradeVals?.length>0 && t?.gradeVals.map((g,j)=>{
                                                return(<TableCell key={j} className="w_grade text-center">{Number(g)}</TableCell>)
                                            })}
                                            <TableCell className="m_width text-center val_bg_yellow">{Number(t?.colC).toFixed(1)}%</TableCell>
                                            <TableCell className="m_width text-center val_bg_blue">{Number(t?.colE).toFixed(1)}%</TableCell>
                                        </TableRow>)
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )
                }):
                <Notification variant="warning" className="mt-4" style={{marginLeft:"12px"}}>
                <p className="text-start mb-0">No grade information found.</p>                
              </Notification>}                
            </div>
            </div>
            }
        </> 
    )
}
export default GradeDistribution;