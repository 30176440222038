import { Spinner } from '../../common/Spinner';
import {DropDown, GrpDropDown,Search} from '../../common/Dropdown';
import {constants} from '../../constants';
import '../../common/global.css';
import {TableContainer,
        Table,
        TableHead,
        TableBody,
        TableCell,
        TableRow,
        TableSortLabel,
        Button,
        Link,
        Card,
        TablePagination,
        Notification} from '@cambridgeassessment/cambridge-ui';
import {useState,useRef,useEffect} from 'react';
import { useReactToPrint } from 'react-to-print';
import {getSchoolsData, getStudentReportData} from '../../services/muleSoftServices';
import './StudentReports.css';
import {useDispatch,useSelector} from 'react-redux';
import { isEmpty } from 'lodash';
import {numberSorting, showPendingNotification, stringSorting} from '../../common/validations';
import { useLocation, useNavigate } from "react-router-dom";
import { setTabNumber } from '../../redux/slice/sideNavigation';
import { setSchoolsData } from '../../redux/slice/dropDownsData';
import { setStudentReport, setTotalReportData } from '../../redux/slice/studentReport';
import { setRecentSelectedSeries } from '../../redux/slice/passRate';

const StudentReports = () =>{
    const savedPassResp = useSelector(state => state.pass),
    initialState={
        spin:true,
        searchString:'',
        seriesSelectedValue:savedPassResp.selectedSeries,
        schoolSelectedValue:{},
        rowsPerPage:25,
        page:0
    },
    [initialData,setInitialData] = useState(initialState),    
    [reportData,setReportData] = useState([]),
    [schoolRes,setSchoolRes] = useState([]),
    [allReportData,setAllReportData] = useState({}),
    initialized = useRef(false),
    seriesDropRef = useRef([]),
    seriesSelection = useRef(false),
    schoolSelection = useRef(false),
    sort = useRef({key:'',ascending:true, id:"", sameColumn:true}),
    paginatedRecords = useRef([]),
    spinnerRef = useRef(),
    notificationStatus = useRef("pending"),
    dispatch = useDispatch(),
    navigate = useNavigate(),    
    stuRecs = useSelector(state => state.stuReport),
    studentReportRef = useRef(null),
    downloadBtn = useRef(""),
    savedSchoolResps = useSelector(state => state.dropDowns),
    userInfo = useSelector(state => state.userInfo),
    supportRole = useSelector((state) => state.new),
    location = useLocation();

    useEffect(()=>{
        window.scrollTo(0,0);
        if(!initialized.current){       
            if(!isEmpty(savedPassResp.seriesDropList)){
                seriesDropRef.current = savedPassResp.seriesDropList;
                if(location.state?.detailToReport){
                    setInitialData(previousState =>{
                        return {...previousState,schoolSelectedValue:location.state?.centre}
                    })
                }else{
                    resetSort();
                }              
            }
            if(savedSchoolResps.schoolData.length){
                let centreDetails;
                if(location.state?.detailToReport){
                    centreDetails = location.state?.centre
                }
                setSchoolRes(savedSchoolResps.schoolData);
                setInitialData((previousState)=>{
                    return {...previousState,spin:true,schoolSelectedValue:centreDetails?centreDetails:savedSchoolResps.schoolData[0]}
                })
                getReportData(initialData.seriesSelectedValue?.label,"initial",centreDetails?.centreID?centreDetails.centreID:savedSchoolResps.schoolData[0].centreID);
            }else{
                if(userInfo.roleLevel === "district" || (supportRole.support && userInfo.emlDistrict)){
                    retrieveSchools([initialData.seriesSelectedValue]);
                }else{
                    getReportData(initialData.seriesSelectedValue?.label,"initial");
                }
            }     
            initialized.current = true;
        }
        
        if(userInfo.roleLevel === 'district' || userInfo.emlDistrict || 
           userInfo.roleLevel === 'exams' || userInfo.emlCentre || 
           userInfo.roleLevel === 'head'){
            downloadBtn.current = "Go to data downloads";
        }
        // eslint-disable-next-line
    },[])

    const getReportData = async (series,from,cId) =>{
        let paginatedRecs=[];
        if(!location.state?.detailToReport){
            let modifiedParams;            
            const {totalLoggedInData,roleFromSAP,orgs,roleLevel,
                    emlCentreObj,emlDistrictObj,
                    emlCentre,emlDistrict,uid} = userInfo,
                    {studentReport} = constants.sideNav.roleBaseApiKeys;
            
            if(roleLevel === "support" && emlCentre){
                modifiedParams = {
                centreId:emlCentreObj.centreId
                }
            }else if(roleLevel === "support" && emlDistrict){
                modifiedParams = {
                districtId:emlDistrictObj.districtId,
                roleLevel:emlDistrictObj.emlLevel,
                centreId:cId
                }
            }else if(from === "initial"){
                modifiedParams = {
                    centreId:cId
                }
            }     
            const params = {token:totalLoggedInData.access_token,
                            roleName:roleFromSAP,
                            centreId:modifiedParams?.centreId?modifiedParams.centreId:
                                    initialData.schoolSelectedValue?.centreID?initialData.schoolSelectedValue.centreID:
                                    orgs[0]?.sourceSystems[0]?.orgId,                                            
                            series:series,
                            bpId:orgs[0]?.bpid,
                            rLevel:studentReport[roleLevel],
                            districtId:userInfo.district,
                            stateId:!isEmpty(userInfo.state)?userInfo.state:
                                    !isEmpty(userInfo.hierarchy) && userInfo.hierarchy[0]?.stateId,
                            roleLevel:studentReport[roleLevel],
                            email:uid},           
            stuReport = await getStudentReportData(params);
            if(stuReport.error){
                setReportData([]);
            }else{
                const stuReportCpy = [...stuReport.data.dataRows],
                    sortedStr = stuReportCpy.sort((a,b)=>{return a["candidateNumber"]-b["candidateNumber"]}),
                    nonEmptyRecs = sortedStr.filter(x=>x.number !== "");            
                setReportData(nonEmptyRecs);
                dispatch(setStudentReport({rows:nonEmptyRecs}));
                if(!nonEmptyRecs.length){
                    notificationStatus.current = showPendingNotification(series);
                }
                setAllReportData(stuReport?.data);
                dispatch(setTotalReportData({totalReportData:stuReport?.data}));
                paginatedRecs = nonEmptyRecs;                                
                if(seriesSelection.current){
                    seriesSelection.current = false;
                }else if(schoolSelection.current){
                    schoolSelection.current = false;
                }
            }
        }else{
            if(stuRecs.dataRows.length < initialData.rowsPerPage){
                const stuReportCpy = [...stuRecs.dataRows],
                    sortedStr = stuReportCpy.sort((a,b)=>{return a["candidateNumber"]-b["candidateNumber"]}),
                    nonEmptyRecs = sortedStr.filter(x=>x.number !== "");
                setReportData(nonEmptyRecs);
                paginatedRecs = nonEmptyRecs;
            }else{
                setReportData(stuRecs.dataRows);
                paginatedRecs = stuRecs.dataRows;
            }                        
            setAllReportData(stuRecs.allReportData);                       
        }
        paginatedRecords.current = paginatedRecs?.slice(initialData.page * initialData.rowsPerPage, initialData.page * initialData.rowsPerPage + initialData.rowsPerPage);
        spinnerRef.current?.spinnerOpen();
        setInitialData(previousState =>{
            return {...previousState,spin:false}
        })
    }

    const selectedOption = (key,li) => {
        if((initialData[key]?.label && initialData[key]?.label === li?.label) ||
          initialData[key]?.centreID && initialData[key]?.centreID === li?.centreID){
          return;
        }
        if(key === "seriesSelectedValue"){
            dispatch(setRecentSelectedSeries({userSelectedSeries:li}));
            seriesSelection.current = true;
        }else if(key === "schoolSelectedValue"){
            schoolSelection.current = true;
        }
        setInitialData(previousState=>{
            return {...previousState,spin:true,[key]:li,page:0}
        });
        if(!isEmpty(location.state)){
            location.state = {};
        }
        resetSort();
        sort.current = { key: "",ascending: true, id:"" }
        dispatch(setStudentReport({rows:[]}));
        location.state = {};
        dispatch(setTotalReportData({totalReportData:{}}));     
    }
    
    const dataSort = (column,headersArr) =>{
        if((!isEmpty(sort.current.id) && column.id !== sort.current.id) || 
           (isEmpty(sort.current.id) && column.col !== "candidateNumber")){
            let previousCol = headersArr.filter(c=>c.sortVisible === true);                                              
            previousCol[0].sortVisible = false;
            column.sortOrder = 'asc';
            column.sortVisible = true;
        }else{
            column.sortOrder = column.sortOrder === 'asc'?'desc':'asc'
        }
        sort.current = { key: column.col,ascending: column.sortOrder === 'asc'?true:false,
                         id:column?.id, sameColumn: column?.id !== sort.current.id} 
        const {key,ascending,sameColumn} = sort.current; 
        if(!location.state?.detailToReport){
            if(key === "centreID" || key === "studentName" || key === "studentStateId" || key === "ethnicity"){
                setReportData(stringSorting(reportData,{ascending:ascending,key:key,needSort:sameColumn}));
            }else{
                setReportData(numberSorting(reportData,{ascending:ascending,key:key,needSort:sameColumn}));
            }
        }else{
            location.state = {};
        }
    }

    const resetSort = () =>{
        let arr = constants.studentReports.tableHeaders;
        if(arr.length){
            arr.forEach(p=>{ 
              p.sortVisible = p.id === "candNumber";
              p.sortOrder = 'asc'; 
            })
        }  
      }

    const pageChange = (event,newPage) => {
        let paginatedRecs = reportData.slice((initialData.page+1) * initialData.rowsPerPage, (initialData.page+1) * initialData.rowsPerPage + initialData.rowsPerPage);
        paginatedRecords.current = paginatedRecs;
        setInitialData(previousState =>{
            return {...previousState,page:newPage};
        })
    };
    const handleChangeRowsPerPage = (event) => {
        let paginatedRecs = reportData.slice(0 * event.target.value, 0 * event.target.value + event.target.value);
        paginatedRecords.current = paginatedRecs;
        setInitialData(previousState =>{
            return {...previousState,rowsPerPage:+event.target.value,page:0};
        })       
      };
    
    const searchValue = (value) =>{
        const originalRows = allReportData?.dataRows;
        let sortedStr,filteredRows;
        if(!isEmpty(value)){
        const cNumRows = originalRows.filter(r=>r.candidateNumber?.includes(value)),
              sNameRows = originalRows.filter(r=>r.studentName?.toLowerCase().includes(value?.toLowerCase())),
              stateIdRows = originalRows.filter(r=>r.studentStateID?.toLowerCase().includes(value?.toLowerCase()));
              filteredRows =  [...cNumRows,...sNameRows,...stateIdRows];
              let totRows = filteredRows.length>originalRows.length?[...originalRows]:filteredRows;
              sortedStr =  totRows.sort((a,b)=>{return a.candidateNumber - b.candidateNumber});
        }else{
            let dataRows = [...originalRows];
            sortedStr = dataRows.sort((a,b)=>{return a.candidateNumber - b.candidateNumber});
        }
        if(!isEmpty(value)){            
            setInitialData(previousState =>{
                return {...previousState,page:0};
            }) 
        }
        paginatedRecords.current = sortedStr;             
        setReportData(sortedStr);
        
    }

    useEffect(()=>{
        if(seriesSelection.current){
            getReportData(initialData.seriesSelectedValue.label,"series");
        }
    },[initialData.seriesSelectedValue])

    useEffect(()=>{
        if(initialData.schoolSelectedValue?.centreID && initialData.seriesSelectedValue?.label && schoolSelection.current){
            getReportData(initialData.seriesSelectedValue.label,"school");
        }
    },[initialData.schoolSelectedValue])

    const setSearchValue = value =>{
        searchValue(value);
        setInitialData(previousState => {return {...previousState,searchString:value};})
    } 
    const handleViewRecord = (currentStudent,recs,index) => {
        if(currentStudent.candidateNumber !== recs[index].candidateNumber){
            recs = reportData.slice(initialData.page * initialData.rowsPerPage, initialData.page * initialData.rowsPerPage + initialData.rowsPerPage);
        }      
        const studentInfo = {
          currentStudent:currentStudent,
          onPageRecs:recs,
          recIndex:index,
          selectedParams:{series:initialData.seriesSelectedValue?.label,
                          centre:initialData.schoolSelectedValue,
                          detailToReport:true},
          centreId:allReportData.centreID,
          districtId:userInfo.district,
          stateId:userInfo.state?userInfo.state:
                 !isEmpty(userInfo.hierarchy) && userInfo.hierarchy[0]?.stateId
        };
        dispatch(setStudentReport({rows:reportData}));
        dispatch(setTotalReportData({totalReportData:allReportData}));
        navigate("/student-details", { state: studentInfo });
      };    

    const downloadPdf = useReactToPrint({
    bodyClass:"m-2 p-2",
    documentTitle:'student_report',
    content: () => studentReportRef.current
    });

    useEffect(()=>{
    spinnerRef.current?.spinnerOpen();
    },[initialData.spin])
    
    const tabChange = (s) => {
        if(s.category === "report"){
            dispatch(setTabNumber({managementTabNumber:-1,reportTabNumber:s.tab}));
        }else{
            dispatch(setTabNumber({managementTabNumber:s.tab,reportTabNumber:-1}));
        }
        navigate(s.path);
    };

    const retrieveSchools = async (series) =>{
            let modifiedParams;
            const {totalLoggedInData,roleFromSAP,roleLevel,
                emlCentreObj,emlDistrictObj,orgs,
                emlCentre,emlDistrict,hierarchy} = userInfo,
                {schoolList} = constants.sideNav.roleBaseApiKeys;
              
            if(roleLevel === "support" && emlCentre){
                modifiedParams = {
                    centreId:emlCentreObj.centreId
                }
            }else if(roleLevel === "support" && emlDistrict){
                modifiedParams = {
                    districtId:emlDistrictObj.districtId,
                    roleLevel:emlDistrictObj.emlLevel
                }
            }

          const params = {token:totalLoggedInData.access_token,
                          roleName:roleFromSAP,
                          districtId:(supportRole.support && modifiedParams.districtId)?modifiedParams.districtId:userInfo.district,
                          roleLevel:schoolList[roleLevel],
                          bpId:orgs[0]?.bpid,
                          stateId:userInfo.state?userInfo.state:
                                 !isEmpty(hierarchy) && hierarchy[0]?.stateId},
              schoolData = await  getSchoolsData(params),
              schoolArr = schoolData?.data?.data;
          if(!isEmpty(schoolArr)){
            let centre;
            if(location.state?.detailToReport){
                centre = location.state?.centre;
                location.state = {};
            }
            setSchoolRes(schoolArr);
            setInitialData((previousState)=>{
                return {...previousState,spin:false,schoolSelectedValue:!isEmpty(centre)?centre:schoolArr[0]}
            })
            dispatch(setSchoolsData({ schoolData:schoolArr}));
            getReportData(series[0].label,"initial",!isEmpty(centre)?centre.centreID:schoolArr[0].centreID);    
         }
    }
    
    const conditionalSort = (h) => {
        if(!isEmpty(h.col)){
            return(
                <TableSortLabel active={h.sortVisible} direction={h.sortOrder} 
                                id={h?.id} onClick={() => dataSort(h,constants.studentReports.tableHeaders)}>{h.label}
                </TableSortLabel>
            )
        }else{
            return(<>{h.label}</>)
        }
    }
         

    return(
    <div ref={studentReportRef} className='studentReportClass'> 
        {initialData.spin ?<Spinner ref={spinnerRef}/>:
        <> 
            <div className="row mt-4">
                <div className="col-lg-6 col-md-8 col-sm-12">
                    <div><label className="lbl text-start studentRep_lbl">{constants.studentReports.header}</label></div>                                                
                </div>
                <div className={`col-lg-6 col-md-4 col-sm-12 ${userInfo.smallScreen?"mt-2":""}`}>
                    {userInfo.roleLevel !== "teacher" && 
                        <Button className={`${window.innerWidth > 991?'float-end':'mt-4 float-start'}`} onClick={() => tabChange({tab:0,path:"/dashboard",category:"report"})}>{downloadBtn.current}</Button>
                    }
                </div>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12">
                {userInfo.roleLevel !== "teacher" && userInfo.roleLevel !== "district" && !userInfo.emlDistrict &&
                    <div className="col-8 text-start mb-4 mt-2">{constants.studentReports.subHeader}</div>}
                {(userInfo.roleLevel === "district" || userInfo.emlDistrict) && 
                    <div className="col-8 text-start mb-4 mt-2">{constants.studentReports.disSubHeaader}</div>}
                {userInfo.roleLevel === "teacher" && 
                    <div className="sub_text studentRep_sub_text mb-4 mt-2">{constants.studentReports.teacherSub}</div>}
            </div>
            

            <div className='row'>
                {(userInfo.roleLevel === "district" || (supportRole.support && userInfo.emlDistrict)) && 
                <div className="col-lg-4 col-md-12 col-sm-12 mb-4">
                <DropDown class="text-start"
                        label='SCHOOL'
                        userSelectedDropdown='schoolSelectedValue'
                        selectedValue={initialData.schoolSelectedValue.centreID}
                        list={schoolRes.length?schoolRes:[]}
                        onChange={selectedOption}
                        option='centreID' value='centreName'
                        mixedStr={true}/>
                </div>}
                <div className="col-lg-3 col-md-12 col-sm-12 mb-4">
                    <GrpDropDown class='text-start'
                                onChange={selectedOption}
                                label={constants.studentReports.dropDownLabels.series} 
                                list={seriesDropRef.current}
                                selectedValue={initialData.seriesSelectedValue.label}
                                userSelectedDropdown='seriesSelectedValue' 
                                option='label' value='label' />                               
                </div>
                <div className='col-lg-2 col-md-12 col-sm-12 mb-4'>
                    <Search changedVal={setSearchValue} label={constants.studentReports.dropDownLabels.searchLabel} class='text-start'/>
                </div>
                <div className='col-7'></div>
            </div>
            {reportData.length>0 && <Card className="card_spacing">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
              <h3 className="card-header text-start mb-2">
              Students
            </h3>
            {(userInfo.roleLevel === "district" || (supportRole.support && userInfo.emlDistrict)) && 
                <p className="mb-4 text-start card-sub-head">
                    {initialData.schoolSelectedValue?.centreID} - {initialData.schoolSelectedValue?.centreName}
                </p>}              
            {((userInfo.roleLevel === 'exams' || (supportRole.support && userInfo.emlCentre)) || 
               userInfo.roleLevel === 'head' || userInfo.roleLevel === 'teacher') && 
                <p className="mb-4 text-start card-sub-head">
                    {initialData.seriesSelectedValue.label}
                </p>}
            
              </div>
              <div className={`col-lg-6 col-md-12 col-sm-12 ${userInfo.smallScreen?"text-start mb-4":"text-end"}`}>
              <Button variant="outlined" startIcon={<svg  fill="#0076BD" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>} onClick={downloadPdf}>Download (pdf)</Button>
              </div>
            </div>        
            <TableContainer>
                <Table aria-label="student report table">
                    <TableHead>                    
                        <TableRow className='h_bottom'>
                            {constants.studentReports.tableHeaders.map((h,i)=>                                
                                { return((((userInfo.roleLevel === 'exams' || (supportRole.support && userInfo.emlCentre)) && h.label !== "Centre") || 
                                        (userInfo.roleLevel === 'district' || (supportRole.support && userInfo.emlDistrict)) || 
                                         userInfo.roleLevel === 'head' || userInfo.roleLevel === 'teacher' && h.label !== "Centre") && 
                                <TableCell key={i} className={h.class}>
                                   {conditionalSort(h)}
                                </TableCell>)})
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {reportData.length>0 && reportData
                                .slice(initialData.page * initialData.rowsPerPage, initialData.page * initialData.rowsPerPage + initialData.rowsPerPage)
                                .map((r,i)=>{
                                    return(<TableRow hover={true} key={i}>
                                                {((userInfo.roleLevel === 'district' || (supportRole.support && userInfo.emlDistrict)) || userInfo.roleLevel === 'head') && <TableCell>{allReportData?.centreID}</TableCell>}
                                                <TableCell>{r.candidateNumber}</TableCell>
                                                <TableCell>
                                                    <Link
                                                        onClick={()=>handleViewRecord(r,paginatedRecords.current,i)}
                                                        className="studentNameCls"
                                                    >
                                                        {r.studentName}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>{r.nationalId}</TableCell>
                                                <TableCell>{r.studentStateId?r.studentStateId:'-'}</TableCell>
                                                <TableCell>{r.graduationYear?r.graduationYear:'-'}</TableCell>
                                                <TableCell>{r.ethnicity?r.ethnicity:'-'}</TableCell>                                            
                                            </TableRow>)})}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination component='div' 
                             count={reportData.length} 
                             page={initialData.page} 
                             rowsPerPage={initialData.rowsPerPage} 
                             rowsPerPageOptions={[25,50,100,250]} 
                             onPageChange={pageChange}
                             onRowsPerPageChange={handleChangeRowsPerPage} />            
            </Card>}
            {!isEmpty(initialData.searchString) && !reportData.length && (userInfo.emlDistrict || userInfo.roleLevel === "district") &&
                <Notification variant="warning" className="mt-4">
                    <p className='text-start'>No results were found for <strong>{initialData.searchString}</strong> at school <strong>{initialData.schoolSelectedValue.centreID} - {initialData.schoolSelectedValue.centreName}</strong> for the exam series <strong>{initialData.seriesSelectedValue.label}</strong>.</p>
                    <p className='text-start'>Please check the options and try again.</p>
                </Notification>}

            {!isEmpty(initialData.searchString) && !reportData.length && !userInfo.emlDistrict && userInfo.roleLevel !== "district" && 
                <Notification variant="warning" className="mt-4">
                    <p className='text-start'>No results were found for <strong>{initialData.searchString}</strong> for the exam series <strong>{initialData.seriesSelectedValue.label}</strong>.</p>
                    <p className='text-start'>Please check the options and try again.</p>
                </Notification>}            

            {isEmpty(initialData.searchString) && !reportData.length && notificationStatus.current === "pending" && (userInfo.emlDistrict || userInfo.roleLevel === "district") &&
                <Notification variant="info" className="mt-4">
                    <p className="text-start mb-2"><strong>{initialData.seriesSelectedValue?.label} exams results pending</strong></p>
                    <p className="text-start">View exam <a className="link" target="_blank" href="https://www.cambridgeinternational.org/usa/students/dates-and-deadlines/">results release dates</a>.</p>
                    <p style={{marginBottom:"10px;"}}>Pass rate reports will be available after this time.</p>
                </Notification>}

            {isEmpty(initialData.searchString) && !reportData.length && notificationStatus.current === "no data" && (userInfo.emlDistrict || userInfo.roleLevel === "district") &&
                <Notification variant="info" className="mt-4">Data not available for {initialData.seriesSelectedValue.label} for the selected options</Notification>}

            {isEmpty(initialData.searchString) && !reportData.length && notificationStatus.current === "pending" && !userInfo.emlDistrict && userInfo.roleLevel !== "district" && 
            !userInfo.emlState && userInfo.roleLevel !== "state" &&
                <Notification variant="info" className="mt-4">
                    <p className="text-start mb-2"><strong>{initialData.seriesSelectedValue?.label} exams results pending</strong></p>
                    <p className="text-start">View exam <a className="link" target="_blank" href="https://www.cambridgeinternational.org/usa/students/dates-and-deadlines/">results release dates</a>.</p>
                    <p className="text-start" style={{marginBottom:"10px;"}}>Pass rate reports will be available after this time.</p>
                </Notification>}
            
            {isEmpty(initialData.searchString) && !reportData.length && notificationStatus.current === "no data" && !userInfo.emlState && userInfo.roleLevel !== "state" &&
             !userInfo.emlDistrict && userInfo.roleLevel !== "district" &&
                <Notification variant="info" className="mt-4">Data not available for {initialData.seriesSelectedValue.label}</Notification>}

            {isEmpty(initialData.searchString) && !reportData.length && userInfo.roleLevel !== "teacher" && 
                <Notification variant="warning" className="mt-4">
                    <p className="text-start mb-2"><strong>Missing Data</strong></p>
                    {userInfo.roleLevel !== "head" && <p className="text-start">Student data is missing. We can not provide detailed reports for your school.</p>}
                    {userInfo.roleLevel === "head" && <p className="text-start">Student data is missing. We can not provide detailed reports for your school. Please contact your Exams Officer. </p>}
                    {(userInfo.roleLevel === "district" || (supportRole.support && userInfo.emlDistrict)) && <p style={{marginBottom:"10px;"}} className="text-start"><a className='custom_link' onClick={() => tabChange({tab:0,path:"/district-data",category:"management"})}>Upload your district data</a></p>}
                    {(userInfo.roleLevel === "exams" || (supportRole.support && userInfo.emlCentre)) && <p style={{marginBottom:"10px;"}} className="text-start"><a className='custom_link' onClick={() => tabChange({tab:0,path:"/student-data",category:"management"})}>Upload your student data</a></p>}
                </Notification>}
            {!reportData.length && userInfo.roleLevel === "teacher" &&  
                <Notification variant="warning" className="mt-4">
                    <p className="text-start mb-0">No grade information found.</p>                
                </Notification>}
        </>
        }
        </div>
    );
}
export default StudentReports;