import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "../../../constants";

import MESSAGES from "../../../common/Messages";
import { uploadData,uploadFile,uploadFileStatus,downloadTemplate, getDistrictAndStateWithCid } from "../../../services/muleSoftServices";
import './TeacherData.css';

import { Avatar, Button, Card,Notification } from "@cambridgeassessment/cambridge-ui";
import { setAlert } from "../../../redux/slice/GlobalMessage";
import { isEmpty } from "lodash";
import { useLocation, useNavigate,Link } from "react-router-dom";
import moment from "moment-timezone";
import { getAssessmentYear } from "../../../common/validations";

const TeacherData = () =>{
    const userInfo = useSelector(state => state.userInfo),
    {totalLoggedInData,roleFromSAP,emlCentreObj,
    orgs,roleLevel,emlDistrict,uid,district} = userInfo,
    supportRole = useSelector(state => state.new),
    dispatch = useDispatch(),
    location = useLocation(),
    navigate = useNavigate(),
    centreIdValue = useRef(''),
    initialized = useRef(false),
    centreName = useRef(),
    redirectPath = useRef('');
    const uploadTeacherMsg = `Upload ${constants.dataManagement.roster} data`,
    [notificationInfoTech,setNotificationInfoTech]= useState({hidden:true,msg:'',fileName:"",showLabel:true,updatedTime:""}),
    [bannerTech,setBannerTech]= useState({hidden:true,variant:"",content:"",title:""}),
    [errorTechFile,setErrorTechFile]= useState(),
    assessmentYear = getAssessmentYear(),
    assessmentYearNum =  Number(assessmentYear)+1,
    headerDesc1 = constants.dataManagement.teachDesc1.replace(/seriesyear/g,assessmentYear),
    desc2 = constants.dataManagement.teachDesc2.replace(/seriesyear/g,assessmentYear),
    headerDesc2 = desc2.replace(/yearplusone/g,assessmentYearNum);
   

    const [msg,setMsg]= useState(uploadTeacherMsg);
    const notificationWebSocket = useSelector(state=>state.notificationWebSocket);
    const {websocketInstance,notificationMsg} = notificationWebSocket;

    const {access_token,profile} = totalLoggedInData;
    const userId = profile?.uid;
    const role = roleFromSAP;

    if(isEmpty(centreIdValue.current)){
        if(location?.state?.centreId){
            centreIdValue.current = location.state.centreId;
            redirectPath.current = location.state.redirectTo;
            centreName.current = location.state.centreName;
        }else if(supportRole.support){
            centreIdValue.current = emlCentreObj.centreId;
            centreName.current = emlCentreObj.centreName;
        }else{
            centreIdValue.current = orgs[0]?.sourceSystems[0]?.orgId;
        }                                
    }
    const params={
      centreId:centreIdValue.current,
      assessmentYear:assessmentYear,
      role:role,
      bpId:userInfo.orgs[0]?.bpid,
      districtId:userInfo.district
    }

    useEffect(()=>{
        window.scrollTo(0,0);
        if(!initialized.current){
            initialized.current = true;
            uploadTimeStatus();
        }
    },[]);

    const uploadTimeStatus = async ()=>{
        const updatedTime =  await uploadFileStatus(access_token,params,role,"teacher")
        if(updatedTime?.data?.teacherUploadTimestamp){        
            const date = new Date(updatedTime.data.teacherUploadTimestamp),        
            formattedDate =  moment(date).format('D MMM YYYY h:mm A');
            setNotificationInfoTech((prev)=>{return {...prev,showLabel:false,updatedTime:formattedDate}});
        }
    }
    const getDistrictStateId = async (paramsObj)=> {
        const response = await getDistrictAndStateWithCid(paramsObj)
        return response.data;
    }
    const resetBanner = ()=>{
        setBannerTech((prev)=>{return{...prev,hidden:true,variant:"",content:"",title:""}})
    }
    const resetNotification = ()=>{
        setNotificationInfoTech((prev)=>{return{...prev,hidden:true,msg:"",fileName:""}});
    }
    const handleTeacherMessage = async()=>{
        websocketInstance.onmessage = function (event) {
        console.log("MESSAGE CONNECTION")
        let msg="";
        let response = JSON.parse(event.data);
        window.scrollTo(0,0);
        resetNotification();
        // Virus scan checking
        if(response.type === "virusScan" && response.fileType === "teacher"){           
        if(response.properties.status){
                    setBannerTech((prev)=>{return{...prev,hidden:false,variant:"warning",content:MESSAGES.PROCESSING_FILE.CONTENT,title:MESSAGES.PROCESSING_FILE.TITLE}})
            }else{
            setBannerTech((prev)=>{return{...prev,hidden:false,variant:"error",content:MESSAGES.INFECTED_FILE.CONTENT,title:MESSAGES.INFECTED_FILE.TITLE}})
            }
        }
        // file validation checking
        if(response.type === "fileValidation" && response.fileType ==="teacher"){        
            if(!response.properties.fileUploadStatus){
                return  setBannerTech((prev)=>{return{...prev,hidden:false,variant:"error",content:MESSAGES.UPLOAD_FAIL.ERROR.CONTENT,title:MESSAGES.UPLOAD_FAIL.ERROR.TITLE}})
            }
            if(response.properties.validationStatus){
                setBannerTech((prev)=>{return{...prev,hidden:false,variant:"success",content:MESSAGES.DATA_FILE_VALIDATION.SUCCESS.CONTENT,title:MESSAGES.DATA_FILE_VALIDATION.SUCCESS.TITLE}})
            }else{
                setErrorTechFile(response.properties.validationFile)
                setBannerTech((prev)=>{return{...prev,hidden:false,variant:"error",content:MESSAGES.DATA_FILE_VALIDATION.ERROR.CONTENT,title:MESSAGES.DATA_FILE_VALIDATION.ERROR.TITLE}})
            }
            uploadTimeStatus();   
        }
        setTimeout(()=>resetBanner(),600000)   
        };

    }
    const handleTeacherUpload = async (event)=>{
      resetBanner();
      let fileObj = {file:event.target.files[0]}
      let fileFullName = fileObj.file.name;  
      const requestObj = {centreId:centreIdValue.current,
                         token:access_token,roleName:role,
                         districtId:userInfo.district,
                         bpId:userInfo?.orgs[0]?.bpid}
      const {data:[{centreID,districtID,stateCode}]} = await getDistrictStateId(requestObj)
      const data = {
            "userId":userId,
            "state": `${stateCode}`,
            "district": `${districtID}`,
            "centreId":centreID,
            "fileDesc": "r",
            "fileName": fileFullName,
            "assessmentYear": assessmentYear,
            "bpId":userInfo.orgs[0]?.bpid
      };
      setNotificationInfoTech((prev)=>{return{...prev,fileName:fileFullName,hidden:false,showLabel:true,msg:MESSAGES.UPLOADING}})     
        try{
        const result = await uploadData(access_token,data,roleFromSAP);
        setNotificationInfoTech((prev)=>{return{...prev,msg:MESSAGES.CHECKING_FOR_VIRUSES}})
        const {url} = result?.data?.body?.URL;
        const requestObj = result?.data?.body?.URL?.fields;
        const finalObj = {...requestObj,...fileObj};
        const fileResponse = await uploadFile(access_token,url,finalObj,roleFromSAP);
        const webresponse = await handleTeacherMessage()
        event.target.value = "";
    }catch(err){
            setBannerTech((prev)=>{return{...prev,hidden:false,variant:"error",content:MESSAGES.UPLOAD_FAIL.ERROR.CONTENT,title:MESSAGES.UPLOAD_FAIL.ERROR.TITLE}})
            resetNotification()
            setTimeout(()=>{
                resetBanner()
            },5000)
        }        
    }
    const downloadTeacherTemplate = async()=>{
        const {access_token} = totalLoggedInData,
              params = {token:access_token,centreId:centreIdValue.current,districtId:district,
                        role:roleFromSAP,bpId:orgs[0]?.bpid,uid:uid,rLevel:roleLevel,
                        type:'roster',assessmentYear:assessmentYear},
              res = await downloadTemplate(params);
        if(isEmpty(res?.data) || isEmpty(res.data?.presingedURL) && !isEmpty(res.data?.message) || res.data?.presingedURL?.includes(null)){
            dispatch(setAlert({message:res.data?.message ? res.data.message : "Facing some technical issue while downloading",status:"error"}));
        }else{
            dispatch(setAlert({message:"File downloaded successfully",status:"success"}));
            window.open(res.data?.presingedURL,'_self')
        }
    }

    return(
        <>
          <div className="txt">
            <span className="stu-header tData_lbl lbl text-uppercase text-start">Add {constants.dataManagement.teacher} {constants.dataManagement.teachRoster} - {centreName.current?centreName.current:orgs[0]?.name} {centreIdValue.current}</span>
          </div>
          <div className="tData_sub_text teach_txt mb-2">{headerDesc1}</div>
          <div className="tData_sub_text teach_txt mb-4">{headerDesc2}</div>
          {(roleLevel === "district" || (supportRole.support && emlDistrict)) && <div className="text-start mb-3 mt-3"><Button variant="outlined" onClick={()=>{navigate(redirectPath.current)}}>Back</Button></div>}
          <div className="my-3 bannerCls">
            <Notification className="text-start" hidden={bannerTech.hidden} variant={bannerTech.variant} title={bannerTech.title}>
                {(bannerTech.content ==="success")?<p>You can now see enhanced reports for <Link to="/teacher-reports" className="text-decoration-underline">teachers.</Link></p>:
                (bannerTech.content==="error")?
                <div className="mt-3">
                <p>We detected invalid data and/or blank fields. Download the {" "}<a href={errorTechFile} className="cursor-pointer text-decoration-underline invalid_error">data validation file</a>{" "} now to view error details (file available for ten minutes). Do not make updates in the data validation file.</p>
                <p> To add or change data, download your data template file again and make changes in the new file. Follow guidelines in the {" "}
                    <a href={constants.dataManagement.dataGuidance} className="link" target="_blank">data guidance document.</a>
                </p></div>:bannerTech.content}</Notification>
          </div>
          <Card className="h-auto">
                <div className="row p-4">
                    <div className="col-lg-1 col-md-12 col-sm-12 mb-2"><Avatar className="avatar">1</Avatar></div>
                    <div className="col-lg-5 col-md-12 col-sm-12 mb-2">
                        <p className="text-start avatar-head mb-2">Download {constants.dataManagement.roster} template for {centreIdValue.current}</p>
                        <p className="text-start">{constants.dataManagement.teaStepOneSubHead}</p>
                    </div>
                    <div className={`col-lg-6 col-md-12 col-sm-12 ${window.innerWidth < 821?"text-start":"text-end"}`}>
                        <Button onClick={downloadTeacherTemplate}>{constants.dashboard.downloadIcon} Download {constants.dataManagement.roster} template (.xls)</Button>
                    </div>
                </div>
                <hr className="mx-3 m-0"/>
                <div className="row p-4">
                    <div className="col-lg-1 col-md-12 col-sm-12 mb-2"><Avatar className="avatar">2</Avatar></div>
                    <div className="col-lg-5 col-md-12 col-sm-12 mb-2">
                        <p className="text-start avatar-head mb-2">{constants.dataManagement.teachStepTwoHead}</p>
                        <p className="text-start">{constants.dataManagement.teaStepTwoSubHead}</p>
                    </div>
                    <div className={`col-lg-6 col-md-12 col-sm-12 ${window.innerWidth < 821?"text-start":"text-end"}`}>
                        <Button variant="outlined">
                            <a id="teach_guidance_doc" target="_blank" href={constants.dataManagement.dataGuidance}>{constants.dataManagement.guidanceDoc}</a>
                        </Button>
                    </div>
                </div>
                <hr className="mx-3 m-0"/>
                <div className="row p-4">
                    <div className="col-lg-1 col-md-12 col-sm-12 mb-2"><Avatar className="avatar">3</Avatar></div>
                    <div className="col-lg-5 col-md-12 col-sm-12 mb-2">
                        <p className="text-start avatar-head mb-2">{constants.dataManagement.teachStepThreeHead}</p>
                        <p className="text-start">{constants.dataManagement.teachStepThreeSubHead}</p>
                        <p className={`uploadedTimeLable ${window.innerWidth<768?"teach_update_time":""}`} hidden={notificationInfoTech.showLabel}>Last uploaded on {window.innerWidth<768?<br/>:" "}<strong>{notificationInfoTech.updatedTime}</strong></p>
                    </div>
                    <div className={`col-lg-6 col-md-12 col-sm-12 ${window.innerWidth < 821?"text-start":"text-end"}`}>
                    <Button component="label">{constants.dashboard.uploadIcon} {uploadTeacherMsg}
                            <input type="file"  hidden onChange={handleTeacherUpload} />
                    </Button>
                    </div>
                </div>
                <div className="row px-4    ">
                    <div className="col-lg-1 col-md-12 col-sm-12"></div>
                    <div className="col-lg-10 col-md-12 col-sm-12 text-start mt-2">
                    <Notification
                        variant=""
                        title={`${centreIdValue.current} - ${notificationInfoTech.fileName}`}
                        className="notificationTechCls"
                        hidden={notificationInfoTech.hidden}
                    >
                    <p className="text-start">{notificationInfoTech.msg}</p>
                    </Notification>
                    </div>

                </div>
                
          </Card>
        </>
    )
}
export default TeacherData;