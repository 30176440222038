import {CircularProgress} from '@cambridgeassessment/cambridge-ui';
import { Backdrop } from '@mui/material';
import {forwardRef,useImperativeHandle, useState} from 'react';

 export const Spinner = forwardRef((props,ref) =>{
    const [open,setOpen] = useState(false);    
    
    useImperativeHandle(ref,()=>({
        spinnerOpen(){
            setOpen(!open)
        }
    }));

    return(
    <div>
        <Backdrop open={open} sx={{ color: "white", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress 
            size={80}  
            sx={{position:'absolute', 
                top: '50%',left:'50%',
                marginTop:`${-80/2}px`,
                marginLeft: `${-80/2}px`}}/>
        </Backdrop>
    </div>)
})

export const SectionSpinner = () => {
    return (
        <Backdrop open={true} sx={{ color: "white", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress size={80}
                            sx={{position:'absolute', 
                            top: '50%',left:'50%',
                            marginTop:`${-80/2}px`,
                            marginLeft: `${-80/2}px`}}/>
        </Backdrop>
    );
  }