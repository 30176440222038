import { useDispatch, useSelector } from "react-redux";
import { constants } from "../../../constants";
import MESSAGES from "../../../common/Messages";
import './StudentData.css';
import { Avatar, Button, Card, Notification } from "@cambridgeassessment/cambridge-ui";
import { uploadData,uploadFile, uploadFileStatus, downloadTemplate, getDistrictAndStateWithCid } from "../../../services/muleSoftServices";
import { useEffect, useRef, useState } from "react";
import { setAlert } from "../../../redux/slice/GlobalMessage";
import { isEmpty } from "lodash";
import { useLocation, useNavigate,Link } from "react-router-dom";
import { setTabNumber } from "../../../redux/slice/sideNavigation";
import moment from "moment-timezone";
import { getAssessmentYear } from "../../../common/validations";


const StudentData = () =>{
    const userInfo = useSelector(state => state.userInfo),
    supportRole = useSelector(state => state.new),
    dispatch = useDispatch(),
    location = useLocation(),
    navigate = useNavigate(),
    centreIdValue = useRef(),
    centreName = useRef(),
    redirectPath = useRef(''),
    initialized = useRef(false),
    uploadMsg = `Upload your ${constants.dataManagement.student} data`,
    [msg,setMsg]= useState(uploadMsg),
    [notificationInfo,setNotificationInfo]= useState({hidden:true,msg:'',fileName:"",showLabel:true,updatedTime:""}),
    [banner,setBanner]= useState({hidden:true,variant:"",content:"",title:""}),
    [errorFile,setErrorFile] = useState(''),
    assessmentYear = getAssessmentYear(),
    assessmentYearNum =  Number(assessmentYear)+1,
    headerDesc1 = constants.dataManagement.studentDesc1.replace(/seriesyear/g,assessmentYear),
    desc2 = constants.dataManagement.studentDesc2.replace(/seriesyear/g,assessmentYear),
    headerDesc2 = desc2.replace(/yearplusone/g,assessmentYearNum);

    const notificationWebSocket = useSelector(state=>state.notificationWebSocket);
    const {websocketInstance} = notificationWebSocket;

    const {access_token} = userInfo.totalLoggedInData;
    const userId = userInfo?.totalLoggedInData?.profile?.uid;
    const role = userInfo?.roleFromSAP;

    if(isEmpty(centreIdValue.current)){
        if(location?.state?.centreId){
            centreIdValue.current = location.state.centreId;
            centreName.current = location.state.centreName;
            redirectPath.current = location.state.redirectTo;
        }else if(supportRole.support){
            if(userInfo.emlCentre){
                centreIdValue.current = userInfo.emlCentreObj.centreId;
                centreName.current = userInfo.emlCentreObj.centreName;
            }
        }else{
            centreIdValue.current = userInfo.orgs[0]?.sourceSystems[0]?.orgId;
        }
    }

    const params={
      centreId:centreIdValue.current,
      assessmentYear:assessmentYear,
      role:role,
      bpId:userInfo.orgs[0]?.bpid,
      districtId:userInfo.district
    }

    useEffect(()=>{
        window.scrollTo(0,0);
        if(!initialized.current){
            initialized.current = true;
            uploadTimeStatus();
        }
    },[]);

    const uploadTimeStatus = async ()=>{
        const updatedTime =  await uploadFileStatus(access_token,params,role,"student")
        if(updatedTime?.data?.studentUploadTimestamp){
            const date = new Date(updatedTime.data.studentUploadTimestamp),        
            formattedDate =  moment(date).format('D MMM YYYY h:mm A');
            setNotificationInfo((prev)=>{return {...prev,showLabel:false,updatedTime:formattedDate}})
        }
    }    
    const resetBanner = ()=>{
        setBanner((prev)=>{return{...prev,hidden:true,variant:"",content:"",title:""}})
    }
    const resetNotification = ()=>{
        setNotificationInfo((prev)=>{return{...prev,hidden:true,msg:"",fileName:""}});
    }
    const handleStudentMessage = async()=>{
        websocketInstance.onmessage = function (event) {
        console.log("MESSAGE CONNECTION")
        let msg="";
        let response = JSON.parse(event.data);
        window.scrollTo(0,0);
        resetNotification()
        // Virus scan checking
        if(response.type === "virusScan" && response.fileType === "student"){
            if(response.properties.status){
                setBanner((prev)=>{return{...prev,hidden:false,variant:"warning",content:MESSAGES.PROCESSING_FILE.CONTENT,title:MESSAGES.PROCESSING_FILE.TITLE}})
            }else{
                setBanner((prev)=>{return{...prev,hidden:false,variant:"error",content:MESSAGES.INFECTED_FILE.CONTENT,title:MESSAGES.INFECTED_FILE.TITLE}})
            }
        }
        // file validation checking
        if(response.type === "fileValidation" && response.fileType ==="student"){
            if(!response.properties.fileUploadStatus){
                return  setBanner((prev)=>{return{...prev,hidden:false,variant:"error",content:MESSAGES.UPLOAD_FAIL.ERROR.CONTENT,title:MESSAGES.UPLOAD_FAIL.ERROR.TITLE}})
            }
            if(response.properties.validationStatus){
                setBanner((prev)=>{return{...prev,hidden:false,variant:"success",content:MESSAGES.DATA_FILE_VALIDATION.SUCCESS.CONTENT,title:MESSAGES.DATA_FILE_VALIDATION.SUCCESS.TITLE}})
            }else{
                setErrorFile(response.properties.validationFile)
                setBanner((prev)=>{return{...prev,hidden:false,variant:"error",content:MESSAGES.DATA_FILE_VALIDATION.ERROR.CONTENT,title:MESSAGES.DATA_FILE_VALIDATION.ERROR.TITLE}})
            }
            uploadTimeStatus()
        }
        setTimeout(()=>resetBanner(),600000)
        };
    }

    const handleUpload = async (event)=>{
       resetBanner()
      let fileObj = {file:event.target.files[0]};
        let fileFullName = fileObj.file.name;

        const getDistrictStateId = async (requestData)=> {
            const response = await getDistrictAndStateWithCid(requestData)
            return response.data;
        }
        const requestObj = {centreId:centreIdValue.current,
                            token:access_token,roleName:role,
                            districtId:userInfo.district,
                            bpId:userInfo?.orgs[0]?.bpid}
        const {data:[{centreID,districtID,stateCode}]} = await getDistrictStateId(requestObj)

        const data = {
            "userId":userId,
            "state": `${stateCode}`,
            "district": `${districtID}`,
            "centreId": centreID,
            "fileDesc": "d",
            "fileName": fileFullName,
            "assessmentYear": assessmentYear,
            "bpId":userInfo.orgs[0]?.bpid
      };
      setNotificationInfo((prev)=>{return{...prev,fileName:fileFullName,hidden:false,showLabel:true,msg:MESSAGES.UPLOADING}})
        try{
            const result = await uploadData(access_token,data,role);
            setNotificationInfo((prev)=>{return{...prev,msg:MESSAGES.CHECKING_FOR_VIRUSES}})
            const {url} = result?.data?.body?.URL;
            const requestObj = result?.data?.body?.URL?.fields;
            const finalObj = {...requestObj,...fileObj};
            const fileResponse = await uploadFile(access_token,url,finalObj,role);
           // const uploadTime = await uploadFileStatus(access_token,data,role,"student");            
            const webresponse = await handleStudentMessage();            
            event.target.value = "";    
        }catch(err){

            setBanner((prev)=>{return{...prev,hidden:false,variant:"error",content:MESSAGES.UPLOAD_FAIL.ERROR.CONTENT,title:MESSAGES.UPLOAD_FAIL.ERROR.TITLE}})
            resetNotification()
            setTimeout(()=>{
                resetBanner()
            },10000)
        }

    }
    const downloadStudentTemplate = async()=>{
        const {access_token,emlCentreObj} = userInfo.totalLoggedInData,
              params = {token:access_token,centreId:centreIdValue.current,districtId:userInfo?.district,
                        role:emlCentreObj?.roleName?emlCentreObj.roleName:userInfo?.roleFromSAP,
                        bpId:userInfo.orgs[0]?.bpid,uid:userInfo.uid,rLevel:userInfo.roleLevel,
                        type:'demographic',
                        assessmentYear:assessmentYear},
              res = await downloadTemplate(params);
        if(isEmpty(res?.data) || isEmpty(res.data?.presingedURL) && !isEmpty(res.data?.message) ||res.data?. presingedURL?.includes(null)){
            dispatch(setAlert({message:res.data?.message?res.data.message:"Facing some technical issue while downloading",status:"error"}));
        }else{
            dispatch(setAlert({message:"File downloaded successfully",status:"success"}));
            window.open(res.data?.presingedURL,'_self');
        }

    }
    const tabChange = (s) => {
        dispatch(setTabNumber({managementTabNumber:s.tab,reportTabNumber:-1}));
        navigate(s.path);
    };


    return(
        <>
          <div className="txt">
            <span className="stu-header stdData_lbl lbl text-uppercase text-start">Add {constants.dataManagement.student} data - {centreName.current?centreName.current:userInfo?.orgs[0]?.name} {centreIdValue.current}</span>
          </div>
          <div className="stdData_sub_text stu_txt mb-2">{headerDesc1}</div>
          <div className="stdData_sub_text stu_txt text-start mb-4"><p>{headerDesc2}</p></div>
          {(userInfo.roleLevel === "district" || userInfo.emlDistrict) && <div className="text-start mb-3 mt-3"><Button variant="outlined" onClick={()=>{tabChange({tab:0,path:redirectPath.current})}}>Back</Button></div>}
          <div className="my-3 bannerCls">
            <Notification className="text-start" hidden={banner.hidden} variant={banner.variant} title={banner.title}>
                {(banner.content ==="success")?
                <p>You can now see enhanced {" "} 
                    <Link to="/student-reports" className="text-decoration-underline">Student</Link> and {" "} 
                    <Link to="/demographics" className="text-decoration-underline">Demographic</Link> reports.</p>:
                    (banner.content==="error")?
                <div className="mt-3">                    
                <p>We detected invalid data and/or blank fields. Download the {" "}<a href={errorFile} className="cursor-pointer text-decoration-underline invalid_error">data validation file</a>{" "} now to view error details (file available for ten minutes). Do not make updates in the data validation file.</p>
                <p> To add or change data, download your data template file again and make changes in the new file. Follow guidelines in the {" "}
                    <a href={constants.dataManagement.dataGuidance} className="link" target="_blank">data guidance document.</a>
                </p></div>:banner.content}
            </Notification>
          </div>
          <Card className="h-auto">
                <div className="row p-4">
                    <div className="col-lg-1 col-md-12 col-sm-12 mb-2"><Avatar className="avatar">1</Avatar></div>
                    <div className="col-lg-5 col-md-12 col-sm-12 mb-2">
                        <p className={`text-start avatar-head mb-2 ${window.innerWidth < 821?"":"text-nowrap"}`}>Download {constants.dataManagement.student} data template for {centreIdValue.current}</p>
                        <p className="text-start">{constants.dataManagement.stuStepOneSubHead}</p>
                    </div>
                    <div className={`col-lg-6 col-md-12 col-sm-12 ${window.innerWidth < 821?"text-start":"text-end"}`}>
                        <Button onClick={downloadStudentTemplate}>{constants.dashboard.downloadIcon} Download {constants.dataManagement.student} data template (.xls)</Button>
                    </div>
                </div>
                <hr className="mx-3 m-0"/>
                <div className="row p-4">
                    <div className="col-lg-1 col-md-12 col-sm-12 mb-2"><Avatar className="avatar">2</Avatar></div>
                    <div className="col-lg-5 col-md-12 col-sm-12 mb-2">
                        <p className="text-start avatar-head mb-2">{constants.dataManagement.stuStepTwoHead}</p>
                        <p className="text-start">{constants.dataManagement.stuStepTwoSubHead}</p>
                    </div>
                    <div className={`col-lg-6 col-md-12 col-sm-12 ${window.innerWidth < 821?"text-start":"text-end"}`}>                        
                        <Button variant="outlined">
                            <a id="stu_guidance_doc" target="_blank" href={constants.dataManagement.dataGuidance}>{constants.dataManagement.guidanceDoc}</a>
                        </Button>
                    </div>
                </div>
                <hr className="mx-3 m-0"/>
                <div className="row px-4 pt-4">
                    <div className="col-lg-1 col-md-12 col-sm-12 mb-2"><Avatar className="avatar">3</Avatar></div>
                    <div className="col-lg-5 col-md-12 col-sm-12 mb-2">
                        <p className="text-start avatar-head mb-2">{constants.dataManagement.stuStepThreeHead}</p>
                        <p className="text-start">{constants.dataManagement.stuStepThreeSubHead}</p>
                        <p className={`uploadedTimeLable ${window.innerWidth<768?"stu_update_time":""}`} hidden={notificationInfo.showLabel}>Last uploaded on {window.innerWidth<768?<br/>:""}<strong>{notificationInfo.updatedTime}</strong></p>                       
                    </div>
                    <div className={`col-lg-6 col-md-12 col-sm-12 ${window.innerWidth < 821?"text-start":"text-end"}`}>
                        <Button component="label">{constants.dashboard.uploadIcon} {msg}
                            <input type="file"  hidden onChange={handleUpload} />
                        </Button>
                    </div>
                </div>
                <div className="row px-4">
                    <div className="col-lg-1 col-md-12 col-sm-12"></div>
                    <div className="col-lg-10 col-md-12 col-sm-12 text-start mt-2">
                    <Notification
                        variant=""
                        title={`${centreIdValue.current} - ${notificationInfo.fileName}`}
                        className="notificationCls"
                        hidden={notificationInfo.hidden}
                    >
                    <p className="text-start">{notificationInfo.msg}</p>
                    </Notification>
                    </div>

                </div>
          </Card>
        </>
    )
}
export default StudentData;