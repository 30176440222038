import { useDispatch, useSelector } from "react-redux";
import LABLES from "../../common/labels";
import { constants } from "../../constants";
import { DropDown, GrpDropDown } from "../../common/Dropdown";
import { useEffect, useRef, useState } from "react";
import { Button, Card, Notification, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@cambridgeassessment/cambridge-ui";
import { isEmpty } from "lodash";
import { useReactToPrint } from "react-to-print";
import './TeacherReport.css';
import { setTabNumber } from "../../redux/slice/sideNavigation";
import { useNavigate } from "react-router-dom";
import { setSchoolsData, setSyllabusData } from "../../redux/slice/dropDownsData";
import { getSchoolsData, getStudentReportData, getSyllabusData, getSyllabusReportsData, teacherReportData } from "../../services/muleSoftServices";
import { setAlert } from "../../redux/slice/GlobalMessage";
import { Spinner } from "../../common/Spinner";
import { numberSorting, showPendingNotification, stringSorting } from "../../common/validations";
import { setDistrictNavs, setRecentSelectedSeries } from "../../redux/slice/passRate";
import { SvgIcon } from "@mui/material";


const TeacherReports = () =>{
    const userInfo = useSelector(state => state.userInfo),
          savedPassResp = useSelector(state => state.pass),
          navigate = useNavigate(),
          dispatch = useDispatch(),        
          initialState = {
              spin: true,
              schoolSelectedValue:{},
              seriesSelectedValue:savedPassResp.selectedSeries,
              qualificationSelectedValue: {},
              syllabusSelectedValue: {},
              showDetails:false
          },
          [initialData, setInitialData] = useState (initialState),          
          {seriesDropList,districtNavs} = savedPassResp,
          {spin,schoolSelectedValue,
          seriesSelectedValue,qualificationSelectedValue,
          syllabusSelectedValue,showDetails} = initialData,
          {totalLoggedInData,roleFromSAP,orgs,roleLevel,emlCentre,uid,state,
           emlDistrict,emlCentreObj,emlDistrictObj,hierarchy,district}= userInfo,
          [components, setComponents] = useState([]),
          [sylComponents, setSylComponents] = useState([]),
          [schoolRes,setSchoolRes] = useState([]),        
          seriesDropRef = useRef([]),
          sylSort = useRef({key: "", ascending: true, id:"",
                            columnType:"", compName:"",sameColumn:true}),
          sort = useRef({key: "", ascending: true, id:"",sameColumn:true}),          
          selectedCentre = useRef(""),
          customSubHeaders = useRef([]),
          customHeadersSet = useRef(false),
          secSpinner = useRef(false),
          syllabusInitialized = useRef(false),
          spinnerRef = useRef(""),
          dropSelection = useRef(false),
          teacherRef = useRef(null),
          teacherLinkName = useRef(''),
          syllabusDetail = useRef(''),
          compHeaders = useRef([]),
          notificationStatus = useRef("pending"),
          downloadBtn = useRef(""),
          [syllabusRes,setSyllabusRes] = useState([]);

    let message;

    useEffect(()=>{
      window.scrollTo(0,0);
      const {school,series,qualification,syllabus,
             syllabusRecords,teacherMail,teacherRecords,sylDisplay} =  districtNavs.length?districtNavs[0].params:"";
      if(!syllabusInitialized.current){
          if(!isEmpty(seriesDropList)){
            seriesDropRef.current = seriesDropList;
          }         
          if(series){
            setInitialData((previousState)=>{
              return{...previousState,
                schoolSelectedValue:school,
                qualificationSelectedValue:qualification,
                syllabusSelectedValue:syllabus,
                showDetails:true,
                spin:false}
            })
            teacherLinkName.current = teacherMail;
            syllabusDetail.current = sylDisplay;
            setSylComponents(syllabusRecords);
            setComponents(teacherRecords);
          }else{                    
            if(roleLevel === 'district' || emlDistrict){
              if(!districtNavs.length){
                retrieveSchools([seriesSelectedValue]);
              }              
            }
            if(roleLevel === 'exams' || emlCentre || roleLevel === 'head'){
              if(!districtNavs.length){
                retrieveSyllabus([seriesSelectedValue],constants.dropDownList.qualification[0]);
              }
            }
          }
          resetSort();
          downloadBtn.current = "Go to data downloads";          
          syllabusInitialized.current = true;
      }    
    },[]);

    message = LABLES.BANNER_INFO_EXAM_OFFICER;
    

    const selectedOption = (key, li) => {
      if((initialData[key]?.label && initialData[key].label === li?.label) ||
          schoolSelectedValue?.centreID && schoolSelectedValue.centreID === li?.centreID ||
          syllabusSelectedValue?.sylID && syllabusSelectedValue.sylID === li?.sylID){
          return;
      }
      if(roleLevel === "district" || emlDistrict ||
         roleLevel === "exams" || emlCentre ||
         roleLevel === "head" ){
        if(key === "schoolSelectedValue"){
          selectedCentre.current = li.centreID;
          retrieveSyllabus([seriesSelectedValue],"");
        }else if(key === "seriesSelectedValue"){
          dispatch(setRecentSelectedSeries({userSelectedSeries:li}));
          retrieveSyllabus([li],"");
        }else if(key === "qualificationSelectedValue"){
          retrieveSyllabus([seriesSelectedValue],li);
        }else if(key === "syllabusSelectedValue"){
          reportData(reqParams([seriesSelectedValue],qualificationSelectedValue?.label,li.sylID,schoolSelectedValue?.centreID));
        }
      }
      else if(key === "syllabusSelectedValue"){
        if(showDetails){
          getSyllabusReport(li.sylID,seriesSelectedValue.label);
        }else{
          reportData(reqParams([seriesSelectedValue],
                             qualificationSelectedValue?.label,
                             li.sylID),"");        
        }
      }
      else if(key === "qualificationSelectedValue" && roleLevel !== "head"){
        reportData(reqParams([seriesSelectedValue],
                             li.label,syllabusSelectedValue?.sylID,""));        
      }      
      setInitialData((previousState)=>{
        return {...previousState,[key]: li, spin:true}
      })
      dropSelection.current = true;
      resetSort();
      sylSort.current = ({key: "", ascending: true, id:"",columnType:"", compName:""});
      sort.current = ({key: "", ascending: true, id:""});
    };

    const reqParams = (series,qual,syl,centreId) =>{ 
      let modifiedParams;    
        const {teacherReport} = constants.sideNav.roleBaseApiKeys;        
        if(roleLevel === "support" && emlCentre){
          modifiedParams = {
            centreId:emlCentreObj.centreId
          }
        }else if(roleLevel === "support" && emlDistrict){
          modifiedParams = {
            districtId:emlDistrictObj.districtId,
            roleLevel:emlDistrictObj.emlLevel
          }
        }
      
      const params = {token:totalLoggedInData.access_token,
                      roleName:roleFromSAP,
                      centreId:centreId?centreId:
                               modifiedParams?.centreId?modifiedParams.centreId:orgs[0]?.sourceSystems[0]?.orgId,
                      series:series[0].label,
                      quals:qual,
                      roleLevel:teacherReport[roleLevel],
                      syllabusId:syl,
                      rLevel:roleLevel,
                      stateId:state?state:!isEmpty(hierarchy) && hierarchy[0]?.stateId,
                      bpId:orgs[0]?.bpid,
                      districtId:modifiedParams?.districtId?modifiedParams.districtId:district};
      return params;
    }

    const tableHeaders = (recs) => {
      let uniqueComponentNames = [];
      if(recs.length>0 && recs[0].components.length>0 && !isEmpty(recs[0].components[0].comp)){
        recs.forEach((r,i) => {        
          r.components.forEach((c,i) => {          
              if(uniqueComponentNames.length){
                let newComp = uniqueComponentNames.filter(u=>u === c.comp);
                if(!newComp.length){
                  uniqueComponentNames.push(c.comp)
                }
              }else{
                  uniqueComponentNames.push(c.comp)
              }
                      
          });
        });
      }

      const headers = uniqueComponentNames.map((u, i) => {
        if(!customHeadersSet.current){
          constants.teacherSyllabusCompDynamicSubHeaders.map((s, j) => {
            customSubHeaders.current.push({col:s.col,label:s.label,
                                          id:u+"_"+s.id,
                                          label:s.label, 
                                          sortVisible:s.sortVisible, 
                                          sortOrder:s.sortOrder,
                                          component:u});
          })
        } 
        return (
          <TableCell
            className={i % 2 > 0 ? "col_odd_bg text-center" : "text-center"}
            key={i}
            colSpan={3}
          >
            {u}
          </TableCell>
        );
      });
      customHeadersSet.current = true;
      compHeaders.current = uniqueComponentNames;
      return headers;
    };

    const tableSubHeads = () => {
      const subHeaders = compHeaders.current.map((r,i)=>{
        return constants.teacherSyllabusCompDynamicSubHeaders.map((s, j) => {
          let subHead = customSubHeaders.current.filter(c=>c.id === r+"_"+s.id),
              attrs = subHead[0];
          return (
            <TableCell
              className={i % 2 > 0 ? "col_odd_bg text-center comp_col_width w-5" : "text-center comp_col_width w-5"}
              key={j}
              colSpan={1}>
              {<TableSortLabel active={attrs?.sortVisible} direction={attrs?.sortOrder} 
                              onClick={() => syllabusSort(attrs,'dynamic')}>{attrs?.label}
              </TableSortLabel>}
            </TableCell>
          );
        });
      })      
      return subHeaders;
    }

    const componentColumnsData = (rec) => {
      const dataAsPerCompsOrder = compHeaders.current.map((h,i)=>{
        let eachCompData = rec?.components.filter(c=>c.comp === h);
          return constants.teacherSyllabusCompDynamicSubHeaders.map((s, j) => {
            if(eachCompData.length>0){
              return (
                <TableCell
                  className={i % 2 > 0 ? "col_odd_bg text-center" : "text-center"}
                  key={j + i}
                  colSpan={1}
                >
                  {rec.finalTotal === null?"-":
                  ((s.col === "mark" || s.col === "grade") && 
                  (eachCompData[0][s.col] === null || eachCompData[0][s.col] === ""))?"-":
                  (s.col === "mark" && eachCompData[0][s.col] !== null)?
                  Number(eachCompData[0][s.col]).toFixed(1):
                  eachCompData[0][s.col]}
                </TableCell>
              );
            }else{
              return (
                <TableCell className={i % 2 > 0 ? "col_odd_bg text-center" : "text-center"} key={j + i} colSpan={1}>{"-"}</TableCell>
              );
            }
          });
        
      })
      return dataAsPerCompsOrder;
    };

    const showTeacherDetails = (rec) =>{
      teacherLinkName.current = rec.mail;
      syllabusDetail.current = rec.syllabus+" - "+rec.syllabusName;
      resetSylSort();
      setInitialData((previousState) => {
        return { ...previousState, showDetails:true,spin:true };
      });
      getSyllabusReport(rec.syllabus,seriesSelectedValue.label);        
    }

    const getReportData = async (series,candNum) =>{
      let stuRec;
      const {studentReport} = constants.sideNav.roleBaseApiKeys;
      const params = {token:totalLoggedInData.access_token,
                roleName:roleFromSAP,
                centreId:schoolSelectedValue?.centreID?schoolSelectedValue.centreID:
                         emlCentre? emlCentreObj.centreId:orgs[0]?.sourceSystems[0]?.orgId,
                series:series,
                rLevel:studentReport[roleLevel],
                roleLevel:'centre',
                bpId:orgs[0]?.bpid,
                districtId:userInfo.district,
                stateId:!isEmpty(userInfo.state)?userInfo.state:
                        !isEmpty(userInfo.hierarchy) && userInfo.hierarchy[0]?.stateId,
                candNumber:candNum},           
      stuReport = await getStudentReportData(params);
      if(stuReport?.error){
        console.log(stuReport.error);
      }else{
        return stuReport;
      }
             
    }
    
    const studentTabChange = async (candNum) =>{
      setInitialData((previousState) => {
        return { ...previousState, spin:true };
      });     
        let details = await getReportData(seriesSelectedValue?.label,candNum),dNavs = [];
      if(details?.data?.dataRows?.length>0){      
        const teacherViewInfo = {
          currentStudent:details.data.dataRows[0],reDirectTo:"/teacher-reports",tab:4,
          centName:schoolSelectedValue.centreName,
          series:details.data.series,
          centreId:details.data.centreID,
          districtId:userInfo.district,
          stateId:userInfo.state?userInfo.state:
                  !isEmpty(userInfo.hierarchy) && userInfo.hierarchy[0]?.stateId
        };
        dNavs.push({params:{school:schoolSelectedValue,
                            series:seriesSelectedValue,
                            qualification:qualificationSelectedValue,
                            syllabus:syllabusSelectedValue,                           
                            syllabusRecords:sylComponents,
                            teacherMail:teacherLinkName.current,
                            sylDisplay:syllabusDetail.current,
                            teacherRecords:components}})
        dispatch(setDistrictNavs({districtNavs:dNavs}));
        navigate("/student-details",{state:teacherViewInfo});
        dispatch(setTabNumber({managementTabNumber:-1,reportTabNumber:3}));
      }else{
        spinnerRef.current?.spinnerOpen();
        setInitialData((previousState) => {
          return { ...previousState, spin:false };
        });
        dispatch(setAlert({message:constants.alertMsgs.noDetail,status:"error"}));
      }   
    }

    const retrieveSyllabus = async (series,qualification) =>{
      let sortedSyllabuses = [],qual,syl;
      if(districtNavs.length){
        syl = districtNavs[0].params.syllabus;
      }
      qual = !isEmpty(qualification)? qualification :
             qualificationSelectedValue?.label?qualificationSelectedValue:
             constants.dropDownList.qualification[0];      
           
      const req = reqParams(series,qual?.label,"",selectedCentre.current),
            syllabusData = await  getSyllabusData(req);
            sortedSyllabuses = syllabusData?.data?.data.sort((a, b) => { 
              return a['sylID'] - b['sylID']; 
            });     
        setSyllabusRes(sortedSyllabuses);          
        if(!isEmpty(sortedSyllabuses)){
          if(sortedSyllabuses.length>1){
            sortedSyllabuses.unshift({sylID:"All syllabuses",sylName:"All syllabuses"});
          }
          dispatch(setSyllabusData({syllabusData:sortedSyllabuses}));            
          setInitialData((previousState) => {
            return {
              ...previousState,
              syllabusSelectedValue:!isEmpty(syl)?syl:sortedSyllabuses[0],
              seriesSelectedValue:series[0],
              qualificationSelectedValue: qual
            };
          });
          req.syllabusId = !isEmpty(syl)?syl.sylID:sortedSyllabuses[0].sylID;
          req.quals = qual?.label;
          if(showDetails && !sylComponents.length){
            getSyllabusReport(req.syllabusId,series[0].label);
          }                                                 
        }else{
          spinnerRef.current?.spinnerOpen();
          setComponents([]);
          notificationStatus.current = showPendingNotification(series[0].label);
          setInitialData((previousState) => {
            return {
              ...previousState,
              spin:false,
              seriesSelectedValue:series[0],
              syllabusSelectedValue:{},
              qualificationSelectedValue:qual.label?qual:constants.dropDownList.qualification[0]
            };
          });
        }
        if(districtNavs.length){          
          dispatch(setDistrictNavs({districtNavs:[]}));
        }else{
          reportData(req);
        }
    }

    const getSyllabusReport = async (sylId,seriesId) => {
      let modifiedParams;
      const {syllabusReport} = constants.sideNav.roleBaseApiKeys;      
      if(roleLevel === "district" || roleLevel === "exams" || 
        roleLevel === "head" || emlDistrict || emlCentre){
        modifiedParams = {
          roleLevel:'teacher',
          centreId:districtNavs.length?districtNavs[0]?.params?.school?.centreID:
                   schoolSelectedValue?.centreID?
                   schoolSelectedValue.centreID:
                   emlCentreObj?.centreId?emlCentreObj.centreId:
                   orgs[0]?.sourceSystems[0]?.orgId
        }
      }else{
        modifiedParams = {
          centreId:schoolSelectedValue.centreID
        }
      }

      const params = {token:totalLoggedInData.access_token,
                      roleName:roleFromSAP,
                      centreId:modifiedParams?.centreId?modifiedParams.centreId:orgs[0]?.sourceSystems[0]?.orgId,
                      series:seriesId,
                      syllabusId:sylId,
                      bpId:orgs[0]?.bpid,
                      districtId:district?district:!isEmpty(hierarchy) && hierarchy[0]?.districtId,
                      stateId:!isEmpty(state)?state:!isEmpty(hierarchy) && hierarchy[0]?.stateId,
                      email:teacherLinkName.current,
                      roleLevel:modifiedParams?.roleLevel?modifiedParams.roleLevel:syllabusReport[roleLevel]},                      
      syllabus = await getSyllabusReportsData(params);      
      if(syllabus?.error){
        let preDefinedAlertMsg = constants.alertMsgs[syllabus.message];       
        dispatch(setAlert({message:!isEmpty(preDefinedAlertMsg)?preDefinedAlertMsg:syllabus.message,
                         status:syllabus.type}))
        spinnerRef.current?.spinnerOpen();
        setInitialData((previousState) => {
          return {...previousState,spin: false};
        });
      }else{
        let sortedRows;       
        if(syllabus?.data?.dataRows){        
            sortedRows = syllabus?.data?.dataRows?.sort((a, b) => {
              return a.candNumber - b.candNumber;
            });
          spinnerRef.current?.spinnerOpen();
          setInitialData((previousState) => {
            return {
              ...previousState,
              spin: false
            };
          });
          setSylComponents(!isEmpty(sortedRows)?sortedRows:[]);
          if(!sortedRows.length){
            notificationStatus.current = showPendingNotification(params.series);
          }
        }
        else if(syllabus?.data?.length>0){
          let sortedComps=[];
          syllabus.data.forEach(d=>{            
            sortedComps = !sortedComps.length?d.dataRows:[...sortedComps,...d?.dataRows]
          })
          sortedComps = sortedComps.sort((a,b)=>{
            return a.candNumber - b.candNumber;
          })      
          setSylComponents(sortedComps);    
          spinnerRef.current.spinnerOpen();
          setInitialData((previousState) => {
            return {...previousState,spin: false};
          });
          if(!sortedComps.length){
            notificationStatus.current = showPendingNotification(params.series);
          }
        }          
      }
    };

    useEffect(()=>{
      if(spin){
        spinnerRef.current.spinnerOpen();
      }
    },[spin])

    const dataSort = (column,headersArr) =>{
      if((sort.current.id !== "" && column.id !== sort.current.id) || 
         (sort.current.id === "" && column.col !== "syllabus")){     
        let previousCol = headersArr.filter(c=>c.sortVisible === true);                                                    
        previousCol[0].sortVisible = false;
        column.sortOrder = 'asc';
        column.sortVisible = true;
      }else{
        column.sortOrder = column.sortOrder === 'asc'?'desc':'asc'
      }
      sort.current = { key: column.col, ascending: column.sortOrder === 'asc'?true:false, 
                       id:column?.id,sameColumn:column.id !== sort.current.id };

      const {key,ascending,id,sameColumn} = sort.current;      
      if(key === "syllabus" || key === "syllabusName" || key === "mail"){        
        setComponents((key === "syllabusName" || key === "mail") ? 
                      stringSorting(components,{ascending:ascending,key:key,needSort:sameColumn}):
                      numberSorting(components,{ascending:ascending,key:key,needSort:sameColumn}));
      }else{
        let newArr = [];
        let gradeArrToSort = components.map((c)=>{ return {'sylId':c.syllabus,'gIndexValue':c.grades[id]} })
        let sorted = numberSorting(gradeArrToSort,{ascending:ascending,key:'gIndexValue',needSort:sameColumn})
        sorted.map((s) =>{
          let recs = components.filter(c=>c.syllabus === s.sylId);
          if(recs.length>1){
            let duplicateCount = newArr.filter(n=>n.syllabus === recs[0].syllabus);
            if(duplicateCount.length < recs.length){
              recs.forEach(r=>{
                if(r.grades[id] === s.gIndexValue) 
                  newArr.push(r);
              })
            }
          }else{
              newArr.push(recs[0]);
            }          
          })
          setComponents(newArr);
      }         
  }

  const syllabusSort = (subColumn,colType) =>{
    let previousCol;
      if(sylComponents.length >400){
        secSpinner.current = true;  
      }      
      if((!isEmpty(sylSort.current.id) && subColumn.id !== sylSort.current.id) || 
         (isEmpty(sylSort.current.id) && subColumn.col !== "candNumber")){
        previousCol = customSubHeaders.current.filter(c=>c.sortVisible === true);
        if(!previousCol?.length){
          previousCol = constants.teacherSyllabusCompDefaultSubHeaders.filter(s=>s.sortVisible === true);
        }                                              
        previousCol[0].sortVisible = false;
        subColumn.sortOrder = 'asc';
        subColumn.sortVisible = true;
      }else{
        subColumn.sortOrder = subColumn.sortOrder === 'asc'?'desc':'asc'
      }

      sylSort.current = {key: subColumn.col, 
                          ascending: subColumn.sortOrder === 'asc'?true:false, 
                          id:subColumn?.id, 
                          columnType:colType,
                          compName:subColumn.component,
                          sameColumn:subColumn?.id !== sylSort.current.id };

      let { key,ascending,columnType,compName,sameColumn } = sylSort.current;

      if(columnType === 'static'){
        if(key === 'finalGrade'){
          setSylComponents(stringSorting(sylComponents,{ascending:ascending,key:key,needSort:sameColumn}))
        }else{
          setSylComponents(numberSorting(sylComponents,{ascending:ascending,key:key,needSort:sameColumn}))
        }
      }else{
        let valArr=[],nonValArr=[],sortedValues = [],finalSortList=[];
        sylComponents.map(c=> {
          let sortableData = c.components.filter(s=>s.comp === compName);
          if(sortableData.length && sortableData[0][key] !== null && sortableData[0][key] !== ""){
            valArr.push({candNum:c.candNumber,colVal:sortableData[0][key]})
          }else{
            nonValArr.push({candNum:c.candNumber,colVal:"-"})
          }
        })
        
        if(key === 'grade'){
          valArr = stringSorting(valArr,{ascending:ascending,key:'colVal',needSort:sameColumn});
          nonValArr = stringSorting(nonValArr,{ascending:ascending,key:'colVal',needSort:sameColumn});
        }else{
          valArr = numberSorting(valArr,{ascending:ascending,key:'colVal',needSort:sameColumn});
          nonValArr = numberSorting(nonValArr,{ascending:ascending,key:'colVal',needSort:sameColumn});
        }
        sortedValues = valArr.concat(nonValArr);
        finalSortList = sortedValues.map(s=> {
          let sortByCandNum = sylComponents.filter(c=>c.candNumber === s.candNum);
          return sortByCandNum[0];
        })
        setSylComponents(finalSortList);              
      }
      secSpinner.current = false;
  }
  
  const resetSort = () =>{
    let arr = constants.teacherData.teacherHeaders;
    if(arr.length){
        arr.forEach(p=>{ 
          p.sortVisible = p.id === "syllabus";
          p.sortOrder = 'asc'; 
        })
    }  
  }

  const resetSylSort = () =>{
    let arr = constants.teacherSyllabusCompDefaultSubHeaders;
    if(arr.length){
        arr.forEach(p=>{ 
          p.sortVisible = p.id === "stuId";
          p.sortOrder = 'asc'; 
        })
    }  
  }

    const reportData = async(params) =>{
      const res = await teacherReportData(params);
      if(res?.data && res.data?.dataRows.length>0){
        let arr = [],
        sortedData = numberSorting(res.data?.dataRows,{ascending:true,key:'syllabus',needSort:true});
        sortedData.forEach((d,i)=>{
          if(d.components.length>0){
              d.components.forEach((c,j)=>{
              let grades=[],g=0;                
                if(constants.teacherData.teacherHeaders.length>3){
                  constants.teacherData.teacherHeaders.splice(3);
                }
                for(let key in c.grades){
                  let headerObj = { col: key, label:key, id:g++, sortVisible:false, sortOrder:'asc' }                 
                    constants.teacherData.teacherHeaders.push(headerObj);
                    grades.push(c.grades[key]);
                }
              let obj =  {
                syllabus:d.syllabus,
                syllabusName:d.syllabusName,
                mail:c.teacherEmail,
                grades:grades,
                components:d.components
              }
              arr.push(obj)
            })
          }                  
        })
      setComponents(arr);
      }else{
        setComponents([]);
        notificationStatus.current = showPendingNotification(params.series);
      }
      spinnerRef.current?.spinnerOpen();
      setInitialData((previousState) => {
        return { ...previousState, spin:false };
      });
    }  

    const backToReport = () =>{
      teacherLinkName.current = "";
      syllabusDetail.current = "";
      customSubHeaders.current = [];
      customHeadersSet.current = false;
      if(districtNavs.length){
        setComponents(districtNavs[0].params.teacherRecords);
        if(roleLevel === "district" || emlDistrict){        
          retrieveSchools([seriesSelectedValue]);        
        }else if(roleLevel === 'exams' || emlCentre || roleLevel === 'head'){
          retrieveSyllabus([districtNavs[0].params.series],districtNavs[0].params.qualification);
        }
      }
      setInitialData((previousState) => {
          return { ...previousState, showDetails:false };
      });
    }
    const downloadTeacherPdf = useReactToPrint({
      bodyClass:"m-2 p-2",
      documentTitle:'Teacher_Report',
        content: () => teacherRef.current
    });

    const tabChange = (s) => {
      if(s.category === "report"){
          dispatch(setTabNumber({managementTabNumber:-1,reportTabNumber:s.tab}));
      }else{
          dispatch(setTabNumber({managementTabNumber:s.tab,reportTabNumber:-1}));
      }
      navigate(s.path);
    };

    const retrieveSchools = async (series) =>{
      let modifiedParams;
      const {schoolList} = constants.sideNav.roleBaseApiKeys;
      
      if(roleLevel === "support" && emlCentre){
        modifiedParams = {
          centreId:emlCentreObj.centreId
        }
      }else if(roleLevel === "support" && emlDistrict){
        modifiedParams = {
          districtId:emlDistrictObj.districtId,
          roleLevel:emlDistrictObj.emlLevel
        }
      }
              
      const params = {token:totalLoggedInData.access_token,
                      roleName:roleFromSAP,
                      districtId:modifiedParams?.districtId?modifiedParams.districtId:district,
                      bpId:orgs[0]?.bpid,
                      roleLevel:schoolList[roleLevel]},
            schoolData = await  getSchoolsData(params),
            schoolArr = schoolData?.data?.data;
      if(!isEmpty(schoolArr)){
        let school = !isEmpty(schoolSelectedValue)?schoolSelectedValue:schoolArr[0];
        selectedCentre.current = school.centreID;
        retrieveSyllabus(series,qualificationSelectedValue);
        setSchoolRes(schoolArr);
        setInitialData((previousState)=>{
          return {...previousState,
                  spin:false,
                  schoolSelectedValue:school
                  }
        })
        dispatch(setSchoolsData({ schoolData:schoolArr}));         
      }
    }

    return (
      <div>
      {spin ? (
        <Spinner ref={spinnerRef} />
      ) :(
    <div ref={teacherRef}>
      <div className={`row ${showDetails?"mb-4":""}`}>
        {showDetails && <div className="col-lg-1 col-md-12 col-sm-12 txt text-start d-inline-flex"><div className="row ps-2"><Button variant="outlined" color="secondary" size="small" className="backBtnCls" onClick={backToReport}><SvgIcon className="backIconcls">{constants.studentReports.StudentDetail.icon.back}</SvgIcon>Back</Button></div></div>}
        <div className="col-lg-3 col-md-12 col-sm-12 txt">
            <label className={showDetails ? "lbl_txt text-nowrap":"lbl_teach lbl text-nowrap"}>{LABLES.TEACHER_HEADER}</label>
            {showDetails && <div className="sub_txt mt-1 text-start">{teacherLinkName.current}</div>}
        </div>
        <div className={`col-md-12 col-sm-12 txt ${window.innerWidth <821?"":"teach_download"} ${showDetails?"col-lg-8":"col-lg-9"}`}>
            <Button className={`${window.innerWidth <821?"float-start":"float-end"}`} onClick={() => tabChange({tab:0,path:"/dashboard",category:"report"})}>{downloadBtn.current}</Button>
        </div>
      </div>
      {!showDetails && 
        <div className="sub_text teacherRep_sub_text mt-2">
          {LABLES.TEACHER_SUB_HEADER1}{" "}{LABLES.TEACHER_SUB_HEADER2}{" "}
            <a href={LABLES.TEACHER_SUB_HEAD_LINK} target="_blank" className="link">
              {LABLES.TEACHER_SUB_HEADER3}
            </a>.
        </div>}      
      {showDetails && roleLevel === "exams" && 
      <div className="sub_text teacherRep_sub_text">{LABLES.TEACHER_DETAILS_SUB_HEADER_EXAM_OFFICER}</div>}

      {<div className="row mb-4">
            {(roleLevel === "district" || emlDistrict) && isEmpty(teacherLinkName.current) &&
            <div className="mt-2 col-lg-3 col-md-12 col-sm-12">
              <DropDown class="text-start"
              label='SCHOOL'
              userSelectedDropdown='schoolSelectedValue'
              selectedValue={schoolSelectedValue.centreID}
              list={schoolRes.length?schoolRes:[]}
              onChange={selectedOption}
              option='centreID' value='centreName'
              mixedStr={true}/>
            </div>}
            {isEmpty(teacherLinkName.current) && <div className="mt-2 col-lg-3 col-md-12 col-sm-12">
              <GrpDropDown class="text-start" 
                           onChange={selectedOption}
                           userSelectedDropdown='seriesSelectedValue'
                           label={constants.passRate.dropDownLabels.series}
                           selectedValue={seriesSelectedValue.label} 
                           list={seriesDropRef.current}
                           option='label' value='label' />
            </div>}
            {isEmpty(teacherLinkName.current) && <div className="mt-2 col-lg-3 col-md-12 col-sm-12">
              <DropDown class="text-start" onChange={selectedOption}
                        userSelectedDropdown='qualificationSelectedValue'
                        selectedValue={qualificationSelectedValue.value}
                        label={constants.passRate.dropDownLabels.qual}
                        list={constants.dropDownList.qualification}
                        option='value' value='label' />
            </div>}
            {isEmpty(teacherLinkName.current) && <div className={`mt-2 col-md-12 col-sm-12 ${(roleLevel === "district" || emlDistrict)?"col-lg-3":"col-lg-6"}`}>
              <DropDown
                  class="text-start"
                  userSelectedDropdown='syllabusSelectedValue'
                  selectedValue={syllabusSelectedValue.sylID}
                  onChange={selectedOption}
                  label={constants.syllabusReports.dropDownLabels.qual}
                  list={syllabusRes}
                  mixedStr={true}
                  option='sylID' value='sylName'
                />
            </div>}
            <div className="col-2"></div>
      </div>}
          {!showDetails && components.length>0 &&  <Card className="card_spacing card-bg my-2" style={{height:'auto'}} > 
          <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
              <h3 className="card-header text-start mb-2">
              All teachers
            </h3>
            <p className="mb-4 text-start card-sub-head">
              {seriesSelectedValue?.label}
            </p>
              </div>
              <div className={`col-lg-6 col-md-12 col-sm-12 ${window.innerWidth <821?"mb-4 text-start":"text-end"}`}>
              <Button variant="outlined" startIcon={<svg  fill="#0076BD" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>} onClick={downloadTeacherPdf}>Download (pdf)</Button>
              </div>
            </div>        
            <TableContainer>
                <Table aria-label="teacher report table">
                    <TableHead>                    
                        <TableRow className='h_bottom'>
                            {constants.teacherData.teacherHeaders.map((h,i)=>
                                {return(<TableCell key={i}>
                                          <TableSortLabel active={h.sortVisible} direction={h.sortOrder} 
                                                          id={h?.id} onClick={() => dataSort(h,constants.teacherData.teacherHeaders)}>
                                            {h.label}
                                          </TableSortLabel>
                                        </TableCell>)})
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {components.length>0 && components.map((r,i)=>{
                                return(<TableRow hover={true} key={i}>
                                          <TableCell>{r.syllabus}</TableCell>
                                          <TableCell>{r.syllabusName}</TableCell>
                                          <TableCell><a href="#" onClick={() =>showTeacherDetails(r)} className='link'>{r.mail}</a></TableCell>
                                          {r.grades.length>0 && r.grades.map((g,j)=>{return(<TableCell key={j}>{g}</TableCell>)})}
                                       </TableRow>)
                            })}
                    </TableBody>
                </Table>
            </TableContainer>            
          </Card>}
          {notificationStatus.current === "pending" && isEmpty(components) && roleLevel !== 'state' && roleLevel !== 'teacher' && !showDetails && 
            <Notification variant="info" className="mt-4">
                <p className="text-start"><strong>{seriesSelectedValue.label} exams results pending</strong></p><p className="text-start">View exam <a className="link" target="_blank" href="https://www.cambridgeinternational.org/usa/students/dates-and-deadlines/">results release dates.</a></p>
                <p className="text-start">You will be able to view demographics reports once results have been released.</p>
            </Notification>}

          {isEmpty(components) && notificationStatus.current === "no data" && roleLevel !== 'state' && roleLevel !== 'teacher' && !showDetails && 
            <Notification variant="info" className="text-start">
              Data not available for {seriesSelectedValue?.label} for the selected options
            </Notification>}

          {isEmpty(components) && !showDetails && 
            <Notification variant="warning" className="mt-4">
                <p className="text-start mb-2"><strong>Missing data</strong></p>
                {roleLevel === 'head' && <p className="text-start">Teacher roster data is missing. We can not provide detailed teacher reports for your school. Please contact your Exams Officer.</p>}
                {(roleLevel === 'district' || emlDistrict) && <p className="text-start">Teacher roster data is missing. You can upload this info now and boost your reports.</p>}                    
                {(roleLevel === 'district' || emlDistrict) && <p style={{marginBottom:"10px;"}} className="text-start">
                                                                <a className='custom_link' onClick={() => tabChange({tab:0,path:"/district-data",category:"management"})}>Upload your district data</a>
                                                              </p>}
                {(roleLevel === 'exams' || emlCentre) && <p className="text-start">Teacher roster data is missing. We can not provide detailed teacher reports for your school.</p>}
                {roleLevel !== 'head' && roleLevel !== 'district' && !emlDistrict && <p style={{marginBottom:"10px;"}} className="text-start">
                                                                                        <a className='custom_link' onClick={() => tabChange({tab:1,path:"/teacher-data",category:"management"})}>Upload teacher rosters</a>
                                                                                    </p>}                        
          </Notification>}
          {showDetails && sylComponents.length>0 && <Card className="card_spacing" style={{height:'auto'}}>           
            <div className="row">
                <div className="col-6"></div>
                <div className="col-6 text-end mb-4">
                <Button variant="outlined" startIcon={<svg  fill="#0076BD" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                  <path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>} onClick={downloadTeacherPdf}>
                  Download (pdf)
                </Button>
                </div>
            </div> 
            <TableContainer>
                <Table className="t_border" style={{width:"16px"}}>
                    <TableHead>
                        <TableRow>
                            <TableCell title={syllabusDetail.current} 
                                      className="lh-lg d-inline-block text-truncate col_width">
                              <a>{syllabusDetail.current}
                              </a>
                            </TableCell>
                            <TableCell className="fg_bg_color text-center tot_col_width" colSpan={2}>Final grades</TableCell>
                            {sylComponents.length>0 && tableHeaders(sylComponents)}
                        </TableRow>
                        <TableRow>
                            {
                              constants.teacherSyllabusCompDefaultSubHeaders.map(s=>{
                                return(
                                  <TableCell className={s.class} colSpan={s.colSpan}>
                                    <TableSortLabel active={s.sortVisible} direction={s.sortOrder} 
                                                    id={s?.id} onClick={() => syllabusSort(s,'static')}>
                                              {s.label} {s.label === 'Students' && `(`+ sylComponents.length +`)`}
                                    </TableSortLabel>
                                  </TableCell>
                                )
                              })
                            }
                            {sylComponents.length>0 && tableSubHeads()}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                  {sylComponents.length>0 && sylComponents.map((c, i) => {
                    return (
                      <TableRow key={i} hover={true}>
                        <TableCell title={ c.candNumber + " - " + c.studentName } className="text-truncate col_width text-start">
                          <a className="syl_link" 
                             onClick={()=>studentTabChange(c.candNumber)}>
                          {c.candNumber + " - " + c.studentName}</a>
                        </TableCell>
                        <TableCell className="fg_bg_color text-center">
                          {c.finalTotal === null ? "-":Number(c.finalTotal).toFixed(1)}
                        </TableCell>
                        <TableCell className="fg_bg_color text-center">
                          {c.finalGrade === null ? "-":c.finalGrade}
                        </TableCell>
                        {componentColumnsData(c)}
                      </TableRow>
                    );
                  })}
                </TableBody>
                </Table>
            </TableContainer>            
          </Card>
          }
          {showDetails && notificationStatus.current === "pending" && !sylComponents.length && roleLevel !== 'teacher' && 
            <Notification variant="info" className="mt-4">
              <p className="text-start mb-2"><strong>{seriesSelectedValue?.label} exams results pending</strong></p>
              <p className="text-start">View exam <a className="link" target="_blank" href="https://www.cambridgeinternational.org/usa/students/dates-and-deadlines/">results release dates</a>.</p>
              <p className="text-start" style={{marginBottom:"10px;"}}>Pass rate reports will be available after this time.</p>
            </Notification>}
            
          {showDetails && notificationStatus.current === "no data" && !sylComponents.length && roleLevel !== 'teacher' &&
            <Notification variant="info" className="mt-4 text-start">Data not available for {seriesSelectedValue?.label} for the selected options</Notification>
          }
    </div>)}</div>)
}
export default TeacherReports;